import request from '@/api-v2/request';
import { FileSignedUrlDTO } from '@/types/dto/FileSignedUrlDTO';
import { FileStatusDTO } from './types/FileStatusDTO';
import { ParseFileStatusDTO } from './types/ParseFileStatusDTO';
import FileDownload from 'js-file-download';
import { ParsedFileDTO, ParsedPortfolioDTO } from './types/ParsedPortfolioDTO';
import { FileServicePutObjectRequestDTO, FileServicePutObjectResponseDTO } from './types/FileServicePutObject';
import { FileTypeConstants } from '@/constants/FileTypeConstants';
import { UploadFileRequestDTO } from './types/UploadFileRequestDTO';
import axios from 'axios';
import CommonUtils from '@/utils/CommonUtils';
import { RouteName } from '@/constants/RouteName';
import { sleep } from '@/utils/period';
import { DateTime } from 'luxon';

export class FileService {
  /**
   * use setTimeout to loop every 1 second to wait until file is ready
   */
  public static async getFileSignedUrl(fileSignedUrlDTO: FileSignedUrlDTO): Promise<string> {
    const url = await request({
      url: `/file/signed-url`,
      method: 'GET',
      params: fileSignedUrlDTO,
    }).then((o) => o.data as string);
    if (!url) {
      return new Promise((resolve): void => {
        window.setTimeout((): void => {
          resolve(FileService.getFileSignedUrl(fileSignedUrlDTO));
        }, 1000);
      });
    }
    return url;
  }

  public static sendFilesToDB(filesToUpload: UploadFileRequestDTO[]): Promise<FileStatusDTO[]> {
    return request({
      url: `/file/upload/details`,
      method: 'POST',
      data: filesToUpload,
    })
      .then((res): FileStatusDTO[] => res.data)
      .catch((err) => {
        throw err;
      });
  }

  public static uploadFilesToBucket(data: FileServicePutObjectRequestDTO): Promise<FileServicePutObjectResponseDTO> {
    return request({
      url: `/file/object/upload`,
      method: 'PUT',
      data,
    })
      .then((res): FileServicePutObjectResponseDTO => res.data)
      .catch((err) => {
        throw err;
      });
  }

  public static getParsedFileDetails(fileContributionId: string): Promise<ParseFileStatusDTO[]> {
    return request({
      url: `/file/${fileContributionId}`,
      method: 'GET',
    })
      .then((res): ParseFileStatusDTO[] => res.data)
      .catch((err) => {
        throw err;
      });
  }

  public static getParsedPortfolioDetails(fileType: string): Promise<(ParsedFileDTO | ParsedPortfolioDTO)[]> {
    return request({
      url: `/file/type/${fileType}/detail`,
      method: 'GET',
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Deletes all the files associated of a portfolio across the parseFileStatus and portfolio tables
   */
  public static deletePortfoliosAndAssociatedFiles(
    deletePortfolioList: {
      portfolioId?: string;
      slug?: string;
      fileContributionId?: number;
    }[],
  ): Promise<string> {
    return request({
      url: `/file/portfolios`,
      method: 'DELETE',
      data: deletePortfolioList,
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  public static abortFileParsingProcess(fileContributionId: string): Promise<void> {
    return request({
      url: `/file/${fileContributionId}/abort`,
      method: 'PUT',
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }

  public static downloadTemplate(type: FileTypeConstants): Promise<{ url: string }> {
    return request({
      url: `/file/template`,
      method: 'GET',
      params: {
        type,
      },
    }).then((res) => res.data);
  }

  public static async downloadWithCloudFrontUrl(
    url: string,
    fileExtension: 'pdf' | 'xlsx' | 'csv',
    fileName: {
      tool: RouteName;
      indexName: string;
      date?: string;
    },
  ) {
    // Stop retrying after 5 minutes
    const deadline = DateTime.now().plus({ minutes: 5 }).valueOf();

    while (true) {
      if (DateTime.now().valueOf() > deadline) {
        throw new Error('Timeout after 5 minutes.');
      }

      try {
        await axios.head(url);
        break;
      } catch (err) {
        if (!axios.isAxiosError(err)) {
          throw err;
        }

        if (err.response?.status === 404) {
          await sleep(5000);
          continue;
        }

        if (err.response?.status === 403) {
          // Timeout and return error
          throw new Error('URL has expired. Please try again.');
        }

        throw err;
      }
    }

    const blob = await axios.get(url, { responseType: 'blob' });
    const sanitizedFilename = CommonUtils.getSanitizedFileName(fileName.tool, fileName.indexName, fileName.date);
    FileDownload(blob.data, `${sanitizedFilename}.${fileExtension}`);
  }
}
