import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { uniq } from 'lodash';
import store from '@/store';
import { IProvider } from '@/types/provider';
import { getProviders as apiGetProviders } from '@/api-v2/web/providers';
import { notNull } from '@/utils/notnull';
import { useLocale } from '@/composables/useLocale';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

const PREMIALAB = 'Premialab';

export interface IProviderState {
  providers: IProvider[];
  providerIds: string[];
}

@Module({ dynamic: true, store, name: 'Provider' })
class Provider extends VuexModule implements IProviderState {
  public providers: IProvider[] = [];
  public providerIds: string[] = [];

  public get computedProviderIds() {
    const { canSeePremialabDisclaimer } = useFeatureFlag();
    const companyProviderId = this.providers.find((o) => o.name.en === PREMIALAB);
    if (companyProviderId && canSeePremialabDisclaimer.value) {
      return uniq([companyProviderId.id, ...this.providerIds]);
    }
    return this.providerIds;
  }

  public get disclaimers(): string[] {
    const { locale } = useLocale();
    const { canSeePremialabDisclaimer, canSeeDisclaimerTitle } = useFeatureFlag();
    if (!this.providers) return [];
    return this.computedProviderIds
      .map((providerId): IProvider | undefined => this.providers.find((o) => o.id === providerId))
      .filter((o): o is IProvider => !!o && !!o.disclaimer)
      .sort((a, b) => {
        if (a.name.en === PREMIALAB) return -1;
        if (b.name.en === PREMIALAB) return 1;
        return a.name.en.localeCompare(b.name.en);
      })
      .map((o): string => {
        if (!o.disclaimer) return '';

        const disclaimerToUse = o.disclaimer[locale.value] ?? o.disclaimer.en;

        if (canSeePremialabDisclaimer.value) {
          return `<h4>${o.name[locale.value] ?? o.name.en} Disclaimer</h4>${disclaimerToUse}`;
        }
        if (canSeeDisclaimerTitle) {
          return disclaimerToUse;
        }
        return `<h4>Disclaimer</h4>${disclaimerToUse}`;
      });
  }

  @Mutation
  private SET_PROVIDERS(providers: IProvider[]): void {
    this.providers = providers;
  }

  @Mutation
  private SET_PROVIDER_IDS(providerIds: string[]): void {
    this.providerIds = uniq(providerIds);
  }

  @Action({ rawError: true })
  public async GetProviders(): Promise<void> {
    const providers = await apiGetProviders();
    this.SET_PROVIDERS(providers);
  }

  @Action({ rawError: true })
  public async SetProviderIds({ strategies }: { strategies: { providerId: string | null }[] }): Promise<void> {
    const providerIds = strategies.map((o) => o.providerId).filter(notNull);
    this.SET_PROVIDER_IDS(providerIds);
  }
}

export const ProviderModule = getModule(Provider);
