import { DateFormat } from '@/constants/DateFormat';
import { ConstituentRiskModule } from '@/store/barrel';
import {
  IPortfolioTree,
  IPortfolioTreeStrategy,
  IPortfolioTreeSubportfolio,
  isPortfolioTreeStrategy,
  isPortfolioTreeSubportfolio,
} from '@/types/IPortfolioTree';
import { computed, ComputedRef, Ref } from 'vue';
import { DateTime } from 'luxon';
import { createDate } from '@/utils/dateUtils';
import usePortfolioTree from './usePortfolioTree';
import { useIsSystemLoading } from './useSystemLoading';
import { translateKnownStrategyProperty } from '@/utils/strategy';
import { units } from '@/filters';
import useFxConversion from './useFxConversion';
import { MetricConstants } from '@/constants/MetricConstants';
import useStrategyMask from '@/composables/useStrategyMask';
import { notNull } from '@/utils/notnull';
import {
  FilterConstants,
  HistoryGroupBy,
  LEGEND_DATA_MAX,
  RiskTabs,
  UnitConstants,
  VarMetrics,
  VarNotional,
} from '@/types/analytics/ConstituentRisk';
import { findTreeComponentByStrategyCode } from '@/utils/portfolioTree';
import { useFeatureFlag } from './useFeatureFlag';
import { useAnalysisCodesForRisk, useGetHistoricalTrackColor } from '@/utils/ConstituentRisk';
import { useIsPdf } from './usePdf';
import { useConstituentRiskAnalysisSteps } from './useAnalysisSteps';
import { useRiskPreferences } from './useRiskPreferences';
import { IConstituentRiskMetricTrack } from '@/types/IConstituentRiskMetricTrack';

export function useConstituentRiskUtilities() {
  const { masterPortfolioTree, itemUnderAnalysis } = usePortfolioTree();
  const { toCurrency } = useFxConversion();
  const isSystemLoading = useIsSystemLoading();
  const { getStrategyMask } = useStrategyMask();

  const paddingHeight = '1.5rem';
  const shortDivHeight = '250px';
  const tallDivHeight = `calc(${paddingHeight} + calc(${shortDivHeight} * 2))`;

  const positionDate = computed(() => ConstituentRiskModule.positionDate);

  const rollingDate = computed(() => ConstituentRiskModule.rollingDate);

  const isHistoryMode = computed(() => ConstituentRiskModule.isHistoryMode);

  const riskMetrics = computed(() => ConstituentRiskModule.optionsData?.riskMetrics);
  const asset = computed(() => ConstituentRiskModule.optionsData?.asset);
  const customProperties = computed(() => Object.keys(ConstituentRiskModule.optionsData?.segmentCustomOptions ?? {}));

  /**
   * Looks through the entire portfolio to see if at least one strategy is active.
   * @param components Subportfolio components
   */
  const checkForActive = ({
    components,
    analysisCodes,
  }: {
    components: (IPortfolioTreeSubportfolio | IPortfolioTreeStrategy)[];
    analysisCodes: string[];
  }): boolean | undefined => {
    for (const child of components) {
      if (isPortfolioTreeSubportfolio(child)) {
        if (
          checkForActive({
            components: child.components,
            analysisCodes,
          })
        )
          return true;
      }

      if (isPortfolioTreeStrategy(child)) {
        const codeToUse = child.strategy?.code ?? child.reference;
        if (codeToUse && analysisCodes.includes(codeToUse)) return true;
      }
    }
  };

  /**
   * Checks that the portfolio has active strategies and includes the necessary strategies to show the data needed for that tab.
   * The Equity tab requires at least one Equity strategy.
   */
  const checkToUseOverlay = (activeCodes: string[], tree: IPortfolioTree | undefined) => {
    if (!tree) return false;

    if (
      checkForActive({
        components: tree.portfolioTree.components,
        analysisCodes: activeCodes,
      })
    )
      return false;

    return true;
  };

  /**
   * goForward - For YTD, QTD, and MTD, we want to ensure that the datapoint used is the one that is BEFORE
   * the current period. In other words, if we select YTD, we are targeting a date of 31 Dec. If
   * 31 Dec is not a business day, or we do not have data for that day, we don't want 1 Jan, we want
   * the next available date that is in the past (e.g., 30 Dec).
   * Replaces the selected date by the nearest valid constituent date.
   */
  const convertToConstituentDate = ({
    targetDate,
    availableDates,
    isRolling,
    goForward,
  }: {
    targetDate: DateTime;
    availableDates: string[];
    isRolling?: boolean;
    goForward?: boolean;
  }): DateTime | null => {
    if (!availableDates.length) return null;
    if (availableDates.includes(targetDate.toFormat(DateFormat.YYYY_MM_DD))) return targetDate;

    let nearestDate = createDate(availableDates[0]);
    for (const possibleDateString of availableDates) {
      const possibleDate = createDate(possibleDateString);
      const numDaysFromNearestDateCandidate = Math.abs(nearestDate.diff(targetDate, 'days').days);
      const numDaysFromPossibleDate = Math.abs(possibleDate.diff(targetDate, 'days').days);

      const directionIsCorrect = (goForward && targetDate < possibleDate) || (!goForward && targetDate > possibleDate);

      // If the new available targetDate is smaller than the previous nearest targetDate, nearest targetDate gets replaced
      if (numDaysFromPossibleDate < numDaysFromNearestDateCandidate && directionIsCorrect) {
        // Prevent the rolling targetDate to be the same as the position targetDate.
        if (isRolling && positionDate.value && possibleDate.toMillis() === positionDate.value.toMillis()) continue;
        nearestDate = createDate(possibleDate);
      }
    }
    return nearestDate;
  };

  /**
   * Should the overlay be shown
   * @param dataset
   */
  const getShouldShowOverlay = (
    dataset: Ref<Record<string, unknown[]> | unknown[] | null>,
    draft: Ref<IPortfolioTree | undefined>,
  ): ComputedRef<boolean> => {
    const analysisCodesForRisk = useAnalysisCodesForRisk();

    const isPdf = useIsPdf();

    return computed(() => {
      // The overlay should not be shown until the system has finished loading and dataset is not null
      if (isSystemLoading.value || !dataset.value) return false;

      // Once the dataset is defined we can check if it is an empty array or empty object
      if (dataset.value) {
        if (Array.isArray(dataset.value)) {
          if (!dataset.value.length) return true;
        } else if (!Object.keys(dataset.value).length) return true;
      }

      // on PDF we want to use ALL codes. but on the platform, we only use the ones we have pinned
      const codesToUse = isPdf.value
        ? draft.value?.portfolioTree.flattenedAllCodesForRisk ?? []
        : analysisCodesForRisk.value;

      return checkToUseOverlay(codesToUse, draft.value);
    });
  };

  /**
   * Formats the cell appropriately
   */
  const getCellContent = (
    metricVal: number | undefined | null,
    staticVal: string | number | undefined | null,
    column: string,
    optionsUnit?: UnitConstants,
  ) => {
    const cellValue = metricVal ?? staticVal;

    // Formats the number cells to include the correct unit and number styling
    if (typeof cellValue === 'number') {
      // For static cells we do not want any units
      const unit = metricVal != undefined && !!optionsUnit ? optionsUnit : '';

      // For the quantity column, we do not want to round the number
      // And the numDecimalPoints should be 6, instead of 2 which is the platform standard
      if (column === 'Quantity') {
        return units(cellValue, unit, { currency: toCurrency.value, shouldBeRounded: false, numDecimalPoints: 6 });
      }

      // By default, shouldBeRounded should be true to preserve existing behavior
      return units(cellValue, unit, { currency: toCurrency.value, shouldBeRounded: true });
    }

    // Formats the string cells to include the correct translation
    if (typeof cellValue === 'string') {
      // Some cells may have aggregated values, those need to be translated individually
      const delimiter = ' + ';
      return (
        cellValue
          .split(delimiter)
          // For each value, translate it given its current column name
          .map((property) => translateKnownStrategyProperty(column, property) ?? property)
          .join(delimiter)
      );
    }

    return '-';
  };

  /**
   * For convexity graphs,
   * if we have only one series showing,
   * we display the series' name in the legend instead of its value (e.g. Vol+0%).
   */
  const getProcessedLegendData = (
    paramPreferencesMetric: MetricConstants,
    legendData: {
      displayName: string;
      name: string;
      color: string;
    }[],
  ): {
    displayName: string;
    name: string;
    color: string;
  }[] => {
    if (
      (paramPreferencesMetric === MetricConstants.CONVEXITY ||
        paramPreferencesMetric === MetricConstants.CONVEXITY_NORM ||
        paramPreferencesMetric === MetricConstants.CONVEXITY_BS) &&
      legendData.length === 1
    ) {
      return legendData
        .map((e) => {
          if (!itemUnderAnalysis.value) return;
          return { ...e, name: e.name, displayName: getStrategyMask(itemUnderAnalysis.value) };
        })
        .filter(notNull);
    }
    return legendData;
  };

  const getMetricsRange = (
    metrics: Record<string, string | number>[],
  ): {
    hasPositive: boolean;
    hasNegative: boolean;
  } => {
    let hasPositive = false;
    let hasNegative = false;

    for (const item of metrics) {
      Object.keys(metrics).forEach((metric) => {
        const value = item[metric];
        if (typeof value === 'number') {
          if (value < 0) {
            hasNegative = true;
          }
          if (value > 0) {
            hasPositive = true;
          }
        }
      });
    }

    return {
      hasPositive,
      hasNegative,
    };
  };

  const isRPIPortfolio = computed(() => {
    if (!masterPortfolioTree.value) return false;

    return masterPortfolioTree.value.isRpiPortfolio;
  });

  /**
   * Used to show the strategy name (short name/long name/code) from a code.
   * @param key Strategy Code
   */
  const getStrategyNameFromCode = (key: string, idx?: number): string => {
    if (!masterPortfolioTree.value || !itemUnderAnalysis.value) return key;
    const component = findTreeComponentByStrategyCode(masterPortfolioTree.value.portfolioTree, key);

    if (component) {
      let strategyName = getStrategyMask(component);
      // Necessary since some portfolios may only contain a strategy that is the same name as the portfolioName
      // In that case we add a space to the strategy name, so that amcharts does not get confused
      if (idx !== 0 && strategyName === getStrategyMask(itemUnderAnalysis.value)) strategyName = strategyName + ' ';
      return strategyName;
    }
    return key;
  };

  const getVarMetricsOptions = () => {
    const { getShouldDisplayDistributionGraphs } = useFeatureFlag();
    const shouldDisplayDistributionGraphs = getShouldDisplayDistributionGraphs();

    return computed(() =>
      Object.values(VarMetrics)
        .filter((m) => {
          if (shouldDisplayDistributionGraphs.value) return true;
          return m !== VarMetrics.ESG_DISTRIBUTION;
        })
        .map((m) => {
          if (m === VarMetrics.ESG_DISTRIBUTION) return { text: m, value: m };
          return { text: `H-${m}`, value: m };
        }),
    );
  };

  const getVarNotionalOptions = () => {
    const { getShouldDisplayDistributionGraphs } = useFeatureFlag();
    const shouldDisplayDistributionGraphs = getShouldDisplayDistributionGraphs();

    return computed(() =>
      Object.values(VarNotional).filter((m) => {
        // Weight is not a valid option on the risk page, only used on the position page
        if (m === VarNotional.WEIGHT) return false;

        if (shouldDisplayDistributionGraphs.value) return true;
        return m !== VarNotional.FACTOR_DISTRIBUTION;
      }),
    );
  };

  /**
   * Based on the tab we want to show a historical track with a different segment,
   */
  const getTrackSegment = (): FilterConstants | HistoryGroupBy => {
    const { activeAnalysisSubstep } = useConstituentRiskAnalysisSteps();
    const { preferences } = useRiskPreferences();

    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.VAR:
        return preferences.value.var.historyCategory;
      case RiskTabs.STRESS_TEST:
        return preferences.value['stress-test'].historyCategory;
      case RiskTabs.EQUITY:
        return preferences.value.equity.historyCategory;
      case RiskTabs.INTEREST_RATE:
        return preferences.value['interest-rate'].historyCategory;
      case RiskTabs.CREDIT:
        return preferences.value.credit.historyCategory;
      case RiskTabs.FX:
        return preferences.value.fx.historyCategory;
      case RiskTabs.COMMODITY:
        // Group By Commodity is a display only option, we need to use the underlying
        return preferences.value.commodity.historyCategory === HistoryGroupBy.COMMODITY
          ? HistoryGroupBy.UNDERLYING
          : preferences.value.commodity.historyCategory;
      default:
        return FilterConstants.STRATEGY;
    }
  };

  const getVarHistoryLegendData = (data: IConstituentRiskMetricTrack) => {
    const isPdf = useIsPdf();
    const getColor = useGetHistoricalTrackColor();
    const trackSegment = computed(() => getTrackSegment());

    if (
      isPdf.value ||
      (!isPdf.value &&
        (trackSegment.value === FilterConstants.CURRENCY || trackSegment.value === HistoryGroupBy.UNDERLYING))
    ) {
      return Object.keys(data)
        .map((strategyCode, idx) => {
          return {
            displayName: getStrategyNameFromCode(strategyCode),
            name: strategyCode,
            color: getColor(strategyCode, idx),
          };
        })
        .slice(0, LEGEND_DATA_MAX);
    }
    return [];
  };

  const getSanitizedLegendData = (
    data: {
      displayName: string;
      name: string;
      color: string;
    }[],
  ) => {
    return Array.from(new Set(data))
      .slice(0, LEGEND_DATA_MAX)
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  };

  return {
    paddingHeight,
    shortDivHeight,
    tallDivHeight,
    positionDate,
    rollingDate,
    isHistoryMode,
    isRPIPortfolio,
    riskMetrics,
    asset,
    customProperties,
    checkToUseOverlay,
    convertToConstituentDate,
    getShouldShowOverlay,
    getCellContent,
    getProcessedLegendData,
    getMetricsRange,
    getStrategyNameFromCode,
    getVarMetricsOptions,
    getVarNotionalOptions,
    getTrackSegment,
    getVarHistoryLegendData,
    getSanitizedLegendData,
  };
}
