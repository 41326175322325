import { LeverageTypeConstants } from '@/types/portfolio/AllocationLeverageConstants';
import {
  WeightingTypeConstants,
  WeightingTypesWithAllocationConstraints,
} from '@/types/portfolio/AllocationWeightingConstants';
import {
  PrimaryConstraintTypeConstants,
  SecondaryConstraintTypeConstants,
} from '@/types/portfolio/AllocationConstraintsConstants';
import { escapeRegExp } from 'lodash';
import { PortfolioItemResponseDTO } from '@/api-v2/web/discover';
import { IPortfolioTree } from '@/types/IPortfolioTree';

export const translator: Record<WeightingTypeConstants, string> = {
  Equal: 'Equal Weighting',
  Manual: 'Manual Weighting',
  'Risk Parity': 'Risk Parity',
  ERC: 'ERC Weighting',
  PC1: 'PC1 Weighting',
  Custom: 'Custom Weighting',
  None: 'No Weighting',
  'Minimum Variance': 'Minimum Variance',
  'Frontier Volatility Target': 'Volatility Target',
};

export const getSubtext = (portfolio: IPortfolioTree, isPdf = false): string => {
  let retval = '';

  const allocation = portfolio.allocation[portfolio.portfolioTree.portfolioTreeId];

  if (!isPdf) {
    retval += `${(portfolio.portfolioTree.flattenedActiveCodes ?? []).length}/${
      (portfolio.portfolioTree.flattenedAllCodes ?? []).length
    } Active Strategies `;

    retval += `| ${allocation.weighting.type}`;
  }

  const fixedLeverage =
    allocation.weighting.type !== WeightingTypeConstants.CUSTOM &&
    allocation.leverage.type === LeverageTypeConstants.FIXED_LEVERAGE;
  const targetVolatility =
    allocation.weighting.type !== WeightingTypeConstants.CUSTOM &&
    allocation.leverage.type === LeverageTypeConstants.TARGET_VOLATILITY;

  const hasConstraints =
    WeightingTypesWithAllocationConstraints.has(allocation.weighting.type) &&
    allocation.weighting.constraints &&
    (allocation.weighting.constraints.primaryType !== PrimaryConstraintTypeConstants.NONE ||
      allocation.weighting.constraints.secondaryType !== SecondaryConstraintTypeConstants.NONE);

  if (fixedLeverage) retval += ` | ${allocation.leverage.fixedLeverage}%`;
  if (targetVolatility) retval += ` | VT ${allocation.leverage.targetVolatility}%`;
  if (hasConstraints) retval += `| Constraints`;

  return retval;
};

/**
 * Inner implementation for {@link getClonedName}.
 *
 * We split this into 2 function so that the outer function can check the returned name
 * is really unique.
 */
function _getClonedName(
  name: string,
  portfolioNames: string[],
  suffix: string,
): { result: string; prefix: string; num: number } {
  /**
   * Regex for parsing clone info from title.
   *
   * Group 1 is the prefix name
   * Group 2 (maybe null) is the suffix part. Should be used for checking if suffix exist. This will break if suffix is empty string
   * Group 3 (maybe null) is the clone number. If null, there's no clone number in the search title
   */
  const regex = new RegExp(`^(.+?)(${escapeRegExp(suffix)} ?(\\d+)?)?$`);

  /**
   * Match should only be null if `name` is empty string. But we try to handle that case
   */
  const match = regex.exec(name);

  const prefix = match == null ? name : match[1];

  const matches = portfolioNames
    .map((name) => regex.exec(name))
    .filter((match) => {
      if (match == null) {
        return false;
      }

      return match[1] === prefix;
    });

  // Handle the first clone
  const isFirstClone = matches.every((match) => match![2] === undefined);
  if (isFirstClone) {
    return {
      result: `${prefix}`,
      prefix,
      num: 0,
    };
  }

  const nums = matches
    .map((match) => (match![3] === undefined ? 0 : parseInt(match![3], 10)))
    .filter((num) => Number.isFinite(num));

  let maxNum = Math.max(...nums);

  // Handle case where nums is empty. This should be `n == 1`
  // And when num is empty, tho should never be the case because of regex
  if (!Number.isFinite(maxNum) || maxNum < 0) {
    maxNum = 0;
  }

  return {
    result: `${prefix}${suffix} ${maxNum + 1}`,
    prefix,
    num: maxNum + 1,
  };
}

/**
 * Get the cloned name for the given portfolio name.
 *
 * This will return the new unique title in form of `${name}${suffix} ${n}`, or `${name}${suffix}` if n is 0
 *
 * @param name Name of portfolio to be cloned
 * @param portfolioNames List of portfolio names that currently exist
 * @param suffix Suffix of the cloned name. Default is ` - Clone`
 * @returns New unique title name
 */
export function getClonedName(name: string, portfolioNames: string[], suffix = ' - Clone'): string {
  const cloned = _getClonedName(name, portfolioNames, suffix);

  let { result, num } = cloned;
  const { prefix } = cloned;

  const sets = new Set(portfolioNames);

  while (sets.has(result)) {
    // The logic failed somehow. Add a suffix
    result = `${prefix}${suffix} ${++num}`;
  }

  return result;
}

export const sortPortfolios = (portfolios: PortfolioItemResponseDTO[]): PortfolioItemResponseDTO[] => {
  return portfolios
    .map((portfolio) => [portfolio.name.toLowerCase(), portfolio] as const)
    .sort(([aName], [bName]) => (aName > bName ? 1 : -1))
    .map(([, portfolio]) => portfolio);
};
