import { PerfContributionOptionsResponseDTO } from '@/api-v2/admin/perf-contribution/types';
import {
  getAvailablePerformanceContributionOptionsByCodes,
  getPerformanceContributionAvailable,
  getPerformanceContributionConfig,
  getPerformanceContributionData,
  getPerformanceContributionDates,
  PerformanceContributionRequestDTO,
  PerformanceContributionResponseDTO,
} from '@/api-v2/web/performance-attribution';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { chainEnabled, unwrap } from '@/utils/queries';
import { useQuery } from '@tanstack/vue-query';
import { AxiosError } from 'axios';
import { computed, Ref } from 'vue';
import usePremialabQuery from './queries/usePremialabQuery';
import ignoreRetryOn404 from './ignoreRetryOn404';
import { PerformanceContributionReconciliationTypeConstants } from '@/constants/PerformanceContributionReconciliationTypeConstants';

const keys = {
  all: () => [{ scope: 'performance-attribution' }] as const,
  data: (query: Ref<PerformanceContributionRequestDTO | undefined>) => [
    { ...keys.all()[0], entity: 'PerformanceContributionData', query },
  ],
  slug: (slug: Ref<string | null>) => [{ ...keys.all()[0], entity: 'PerformanceContributionDates', slug }],
  options: (slug: Ref<string | null>) => [{ ...keys.all()[0], entity: 'PerformanceContributionOptions', slug }],
  allOptions: (codes: Ref<string[] | null>) => [{ ...keys.all()[0], entity: 'PerformanceContributionOptions', codes }],
  identifier: (slug: Ref<string | null>) => [{ ...keys.all()[0], entity: 'PerformanceContributionAvailable', slug }],
};

export function usePerformanceContributionAvailable(
  slug: Ref<string | null>,
  options: VQQueryOptions<boolean, Error> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value),
  );
  return usePremialabQuery(
    useQuery({
      queryKey: keys.identifier(slug),
      queryFn: () => getPerformanceContributionAvailable(unwrap(slug)),
      ...options,
      enabled,
    }),
    enabled,
  );
}

export function usePerformanceContributionData(
  query: Ref<PerformanceContributionRequestDTO | undefined>,
  options: VQQueryOptions<PerformanceContributionResponseDTO, Error> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!query.value),
  );
  return usePremialabQuery(
    useQuery({
      queryKey: keys.data(query),
      queryFn: () => getPerformanceContributionData(unwrap(query)),
      ...options,
      enabled,
    }),
    enabled,
  );
}

export function usePerformanceContributionDates(
  slug: Ref<string | null>,
  options: VQQueryOptions<string[], Error> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value),
  );
  return usePremialabQuery(
    useQuery({
      queryKey: keys.slug(slug),
      queryFn: () => getPerformanceContributionDates(unwrap(slug)),
      ...options,
      enabled,
      retry(failureCount, error) {
        // we don't have performance contribution for many strategies
        // so if we hit a 404, we don't retry
        if (error instanceof AxiosError) {
          if (error.response?.data.error === 'Not Found') {
            return false;
          }
        }

        // otherwise fallback to global default of two more retries
        return failureCount < 2;
      },
    }),
    enabled,
  );
}

export function usePerformanceContributionOptions(
  slug: Ref<string | null>,
  options: VQQueryOptions<PerfContributionOptionsResponseDTO, Error> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value),
  );
  return usePremialabQuery(
    useQuery({
      queryKey: keys.options(slug),
      queryFn: () => getPerformanceContributionConfig(unwrap(slug)),
      ...options,
      enabled,
      retry: ignoreRetryOn404,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function useAvailablePerformanceContributionIndicesByCodes(
  codes: Ref<string[] | null>,
  reconciliationType: Ref<PerformanceContributionReconciliationTypeConstants>,
  options: VQQueryOptions<PerfContributionOptionsResponseDTO[], Error> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!codes.value?.length),
  );
  return usePremialabQuery(
    useQuery({
      queryKey: keys.allOptions(codes),
      queryFn: () => getAvailablePerformanceContributionOptionsByCodes(unwrap(codes), unwrap(reconciliationType)),
      ...options,
      enabled,
    }),
    enabled,
  );
}
