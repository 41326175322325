import { RouteConfig } from 'vue-router';
import { UserPermission } from '@/constants/UserPermission';
import { RouteName } from '@/constants/RouteName';
import { POSITION } from '@/types/analytics/AnalysisStep';

export const ConstituentPositionRouter: RouteConfig[] = [
  {
    name: RouteName.POSITION,
    path: 'position/:indexUniqueIdentifier',
    meta: {
      title: POSITION.name,
      shouldApplicationSidebarBeShown: true,
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM, UserPermission.CONSTITUENT],
    },
    components: {
      default: () => import('@/views/analytics/analysis-steps/ConstituentPosition.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'analysis-step-toolbar': () =>
        import('@/layout/components/toolbars/AnalysisStepToolbar/ConstituentPositionToolbar.vue'),
    },
  },
];
