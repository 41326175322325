<template>
  <b-row class="mb-4">
    <b-col class="d-flex align-items-center">
      <h5 class="mb-0">
        <dt class="font-weight-500">
          <b-link
            :href="!isKnowledgeBaseSidebar ? `#glossary-${id}` : ``"
            class="text-primary text-decoration-none mb-4"
          >
            {{ title }}
          </b-link>
        </dt>
      </h5>
    </b-col>

    <b-col class="d-flex justify-content-end">
      <b-button
        v-for="category of categories"
        :key="category"
        data-testid="filtered-category"
        :variant="shownCategories.includes(category) ? 'solid-hover-info' : 'outline-info'"
        class="ml-2"
        size="sm"
        @click="$emit('toggle-category', category)"
      >
        {{ category }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { GlossaryCategory } from '@/types/glossary';
import { useKnowledgeBase } from '@/composables/useKnowledgeBase';
import { KnowledgeBaseMode } from '@/types/KnowledgeBase';

export default defineComponent({
  name: 'GlossaryItemHeader',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    categories: {
      type: Array as PropType<Array<GlossaryCategory>>,
      default: () => [],
    },
    shownCategories: {
      type: Array as PropType<Array<GlossaryCategory>>,
      default: () => [],
    },
  },
  emits: {
    'toggle-category': (_val: GlossaryCategory) => true,
  },
  setup() {
    const { mode } = useKnowledgeBase();

    // To put hash in the router only when knowledge base mode is page
    const isKnowledgeBaseSidebar = computed(() => mode.value === KnowledgeBaseMode.SIDEBAR);

    return {
      isKnowledgeBaseSidebar,
    };
  },
});
</script>
