import { ChartTypes } from '@/types/ChartTypes';
import { EquityBasketTabs } from '../IPortfolioTree';
import { AnalysisSubstep } from './AnalysisStep';
import { BasketCommentTypeConstants, BasketStatusConstants } from '@/constants/EquityBasket';

export enum AuditLogEvent {
  PORTFOLIO_CONSTRUCTION = 'Portfolio Construction',
  CHECK_COMPLIANCE = 'Check Compliance',
  RUN_OPTIMIZER = 'Run Optimizer',
  PRODUCT_CREATION = 'Product Creation',
  REQUEST_PRICING_OR_FUNDING = 'Request Pricing/Funding',
  SEND_TO_CALC_AGENT = 'Send to Calc Agent',
  REQUEST_REBALANCE = 'Request Rebalance',
}

// TODO: WAA-10104 - Rename APPROVAL_REQUESTED to PENDING on API side
export enum AuditLogStatus {
  DRAFT = 'Draft',
  COMPLIANT = 'Compliant',
  NON_COMPLIANT = 'Non Compliant',
  APPROVAL_REQUESTED = 'Approval Requested',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
  LIVE = 'Live',
  ARCHIVED = 'Archived',
}

export const EQUITY_BASKET_SIMULATION: AnalysisSubstep = {
  title: 'Simulation',
  graphTypes: [ChartTypes.TRACK_RECORD, ChartTypes.EXPOSURE],
  path: EquityBasketTabs.SIMULATION,
};
export const EQUITY_BASKET_ROLLING: AnalysisSubstep = {
  title: 'Rolling Metric',
  graphTypes: [ChartTypes.ROLLING_VOLATILITY, ChartTypes.ROLLING_SHARPE],
  path: EquityBasketTabs.ROLLING,
};
export const EQUITY_BASKET_PERF_ATTRIB: AnalysisSubstep = {
  title: 'Performance Contribution',
  graphTypes: [
    ChartTypes.PERFORMANCE_ATTRIBUTION_GROUP_BY,
    ChartTypes.CUMULATIVE_MONTHLY_RETURN_BREAKDOWN,
    ChartTypes.RANKING_TABLE,
  ],
  path: EquityBasketTabs.PERFORMANCE_ATTRIBUTION,
};
export const EQUITY_BASKET_CORRELATION: AnalysisSubstep = {
  title: 'Correlation',
  graphTypes: [
    ChartTypes.ROLLING_CORRELATION,
    ChartTypes.CORRELATION,
    ChartTypes.RETURN_REGRESSION,
    ChartTypes.CORRELATION_MATRIX,
    ChartTypes.MST,
  ],
  path: EquityBasketTabs.CORRELATION,
};

interface EquityBasketStatusStyle {
  statusColor: string;
}

interface CompliantStatusStyle {
  icon: string;
  iconColor: string;
}

//  TODO: Confirm the styling with John and Ryan.
const GREY_COLOR = '#b8b8b9';
const TRAFFIC_LIGHT_GREEN = '#00847E';
const PACIFIC_BLUE = '#00A3E0';
const RED_COLOR = '#FF3838';
const TRAFFIC_LIGHT_AMBER = '#E8A215';

export const CompliantStatusStyleConfigMap: Record<BasketCommentTypeConstants, CompliantStatusStyle> = {
  // Compliant is equivalent to having null as basketComplianceStatus
  compliant: {
    icon: 'fa solid fa-circle-check',
    iconColor: TRAFFIC_LIGHT_GREEN,
  },
  error: {
    icon: 'fa solid fa-circle-xmark',
    iconColor: RED_COLOR,
  },
  warning: {
    icon: 'fa solid fa-circle-xmark',
    iconColor: RED_COLOR,
  },
  info: {
    icon: 'fa solid fa-circle-question',
    iconColor: GREY_COLOR,
  },
};

export const EquityBasketStatusStyleConfigMap: Record<BasketStatusConstants, EquityBasketStatusStyle> = {
  DRAFT: {
    statusColor: GREY_COLOR,
  },
  PENDING: {
    statusColor: TRAFFIC_LIGHT_AMBER,
  },
  REJECTED: {
    statusColor: RED_COLOR,
  },
  APPROVED: {
    statusColor: TRAFFIC_LIGHT_GREEN,
  },
  LIVE: {
    statusColor: PACIFIC_BLUE,
  },
  ACKNOWLEDGED: {
    statusColor: TRAFFIC_LIGHT_GREEN,
  },
};
