import { cloneDeep } from 'lodash';
import { Ref, ref, computed, watch, UnwrapRef, unref, readonly } from 'vue';
import { MaybeRef, until, watchIgnorable } from '@vueuse/core';
import type { useQuery } from '@tanstack/vue-query';

export interface UseFormDraftReturn<TData> {
  /**
   * True if there was any change in the form.
   *
   * This field should be treated as read-only.
   */
  dirty: Ref<boolean>;

  /**
   * Getter for the active form value Ref.
   *
   * We are using a getter instead of direct ref here to bypass `vue/no-mutating-props` eslint rule.
   *
   * @see useGetForm
   */
  form(): Ref<UnwrapRef<TData>>;

  /**
   * Reset the form.
   *
   * This will set {@link dirty} to false and re-clone the form from source.
   */
  reset(): void;

  /**
   * Callback to be called after mutation succeed.
   *
   * User of this composable is responsible for calling this method in `onSuccess()` callback in
   * the mutation related to this form.
   *
   * Essentially this will call {@link reset} once when all the queries has updated.
   *
   * This function can be called after the query has refreshed or will be refreshed.
   * If the queries have been refreshed within 200 millisecond, we consider the
   * data has already updated to reflect latest form change and call {@link reset} immediately.
   */
  onMutateSuccess(): void;
}

/**
 * Create a form draft.
 *
 * The user flow for this composable is as following:
 *  1. Calls some `useQuery`, `compute` the form structure as source for submitting later
 *  2. This composable clones the source whenever it is changed
 *  3. The component change the form values via v-model bindings
 *  4. User clicks a button that triggers mutation and send the updated form to the server
 *
 * We may investigate removing this composable when we have proper form library available in our project (after vue 3).
 *
 * To pass data to child component, prefer passing formDraft instead of form directly to workaround/suppress
 * eslint warnings.
 *
 * @param source Source form data that will be used in mutation. If this is
 *        a computed, **inline it** to prevent accidental access the wrong data
 * @param queries List of queries that is required to builds source. Think of
 *        this as the dependency array for `useEffect` (from react)
 */
export function useFormDraft<TData = unknown>(
  source: Ref<UnwrapRef<TData>>,
  queries: MaybeRef<Array<ReturnType<typeof useQuery>>>,
): UseFormDraftReturn<TData> {
  /**
   * Get a clone of `source` variable, unwrapped
   */
  const cloneSource = () => {
    return cloneDeep(source.value);
  };

  // HACK: Use `.value` instead of putting the value directly in the constructor
  // If we put TData in constructor typescript will get panic and messed up.....
  // Seriously what's going on with UnwrapRef...
  const draft = ref<TData>(null as unknown as TData);
  draft.value = cloneSource();

  // Update draft when source change and form is not dirty
  watch(source, () => {
    if (dirty.value) {
      return;
    }

    ignoreUpdates(() => {
      draft.value = cloneSource();
    });
  });

  const dirty = ref(false);

  // Listen to draft and set dirty flag
  const { ignoreUpdates } = watchIgnorable(
    draft,
    () => {
      // No need to do any checking here as all mutations on `draft` inside this
      // composable are wrapped inside `ignoreUpdates`.
      dirty.value = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { deep: true } as any, // FIXME: https://github.com/vueuse/vueuse/issues/2232
  );

  const reset = () => {
    ignoreUpdates(() => {
      draft.value = cloneSource();
      dirty.value = false;
    });
  };

  const form = () => {
    return computed<UnwrapRef<TData>>({
      get() {
        return draft.value;
      },
      set(val) {
        draft.value = val;
      },
    });
  };

  const onMutateSuccess = () => {
    // ATTENTION: Do **not** return the promise here or make this async function.
    // Returning Promise will create deadlock with vue-query as we are waiting
    // for the queries to refresh and vue-query waiting for us to resolve the
    // promise.

    const now = Date.now();
    const updatedAt = unref(queries).map((q) => q.dataUpdatedAt);

    // Do the reset now if the query was updated very recently.
    // This handle the case where user misuse useFormDraft and call onMutateSuccess after refetch.
    const mostRecent = Math.max(...updatedAt.map((d) => d.value));
    if (now - mostRecent < 300) {
      reset();
      return;
    }

    const promises = updatedAt.map((q) => until(q).changed({ timeout: 5 * 1000 }));
    Promise.all(promises).then(() => {
      reset();
    });
  };

  return {
    dirty: readonly(dirty),
    form,
    reset,
    onMutateSuccess,
  };
}

/**
 * Wrapper for `.form()` call in the formDraft.
 *
 * This should make passing formDraft and form easier.
 */
export function useGetForm<TData>(formDraftRef: Ref<UseFormDraftReturn<TData>>) {
  return computed({
    get() {
      return formDraftRef.value.form().value;
    },
    set(val) {
      formDraftRef.value.form().value = val;
    },
  });
}
