import { RouteName } from '@/constants/RouteName';
import { useRouter } from './useRouter';
import { useLastUsedPortfolioSlug } from './useCachedAnalysisParameters';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useCreateDefaultPortfolioTree } from './queries/usePortfolioTreeData';
import { useAllPortfoliosBySlug } from './queries/useDataDiscovery';
import { PortfolioItemResponseDTO } from '@/api-v2/web/discover';
import { VQMutationOptions } from './queries/defineMutation';
import { IPortfolioTree } from '@/types/IPortfolioTree';
import useUser from './useUser';
import { PortfolioType } from '@/types/portfolio';
import useGlobalEventBus from './useGlobalEventBus';
import { Emitter } from 'mitt';
import { ModalEvents, ModalIdParameter } from '@/types/Injectables';
import { useFeatureFlag } from './useFeatureFlag';

/**
 * Get a function that redirect to the first available portfolio.
 *
 * We assume the portfolio currently selected portfolio cannot be chosen.
 * So the first available portfolio will exclude it.
 */
export default function useShowNextPortfolio(
  queryOptions: VQQueryOptions = {},
  mutationOptions: VQMutationOptions<IPortfolioTree, Error> = {},
): {
  /**
   *  Redirect to the first available portfolio
   */
  showNextPortfolio: () => Promise<void>;
  showNextBasket: () => Promise<void>;
} {
  const router = useRouter();
  const { eventBus } = useGlobalEventBus();

  const {
    portfolio: lastPortfolio,
    saveLastUsedPortfolioSlug,
    basket: lastBasket,
    saveLastUsedBasketSlug,
  } = useLastUsedPortfolioSlug();

  const allPortfolioTreesBySlug = useAllPortfoliosBySlug(queryOptions);
  const createDefaultPortfolioTree = useCreateDefaultPortfolioTree(mutationOptions);

  const showNextPortfolio = async () => {
    const openDefaultPortfolio = (portfolioSlug: string) => {
      // Reset last portfolio so that user won't get into deleted portfolio again
      saveLastUsedPortfolioSlug(portfolioSlug);

      router.push({
        name: RouteName.PORTFOLIO_CONSTRUCTION,
        params: {
          indexUniqueIdentifier: portfolioSlug,
        },
      });
    };

    let nextPortfolio: PortfolioItemResponseDTO;
    const portfolios = (allPortfolioTreesBySlug.data.value ?? new Map()).values();

    /**
     * Get the Default Portfolio by slug
     */
    while ((nextPortfolio = portfolios.next().value)) {
      const nameRegExp = /^default-portfolio-.*/;
      if (nextPortfolio.slug != lastPortfolio.value && nameRegExp.test(nextPortfolio.slug)) {
        openDefaultPortfolio(nextPortfolio.slug);
        return;
      }
    }

    /**
     * Create Default Portfolio if not exists / soft-deleted
     * Note that even though this mutation requires no variables, it creates new
     * portfolio for the team so would be better to use mutation instead of query
     */
    const { user } = useUser();
    const { fauxWhitelabelConfig } = useFeatureFlag();
    if (user.value && !fauxWhitelabelConfig.value?.shouldNotAutoCreateDefaultPortfolio) {
      createDefaultPortfolioTree.mutate(undefined, {
        onSuccess(nextPortfolio) {
          openDefaultPortfolio(nextPortfolio.slug);
        },
      });
    }
  };

  const showNextBasket = async () => {
    const openBasket = (basketSlug: string) => {
      // Reset last basket so that user won't get into deleted portfolio again
      saveLastUsedBasketSlug(basketSlug);

      router.push({
        name: RouteName.EQUITY_BASKET,
        params: {
          indexUniqueIdentifier: basketSlug,
        },
      });
    };

    const portfolioToOpen = Array.from(allPortfolioTreesBySlug.data.value?.values() ?? []).find(
      (portfolio) => portfolio.type === PortfolioType.EQUITY_BASKET && portfolio.slug !== lastBasket.value,
    );

    if (portfolioToOpen) {
      openBasket(portfolioToOpen.slug);
      return;
    }

    (eventBus as Emitter<ModalIdParameter>).emit(ModalEvents.OPEN_MODAL, {
      id: 'create-empty-portfolio-modal',
      shouldRedirectToUniverseOnCancel: true,
    });
  };

  return { showNextPortfolio, showNextBasket };
}
