import PinnedStore from '@/store/modules/PinnedStore';
import { computed, ComputedRef, ref, WritableComputedRef } from 'vue';
import { del, set } from 'vue-demi';
import { SettingsModule } from '@/store/barrel';
import { cloneDeep } from 'lodash';
import { PinItemType, PinnedMap } from '@/types/Pinned';
import { IPortfolioTreeSubportfolio, isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { getAllDescendantIds } from '@/utils/portfolioTree';
import { IColors } from '@/types/setting';
import { until } from '@vueuse/core';
import AnalyticsStore from '@/store/modules/AnalyticsStore';
import { ApplicationDefaults } from '@/constants/ApplicationDefaults';

const nextPinnedColorIndex = ref(0);
const hoveredItemId = computed(() => {
  return PinnedStore.hoveredItemId;
});

const colorMap = computed<PinnedMap>({
  get(): PinnedMap {
    return PinnedStore.colorMap;
  },
  set(newVal: PinnedMap): void {
    PinnedStore.SetColorMap(newVal);
  },
});

/**
 * A map of strategy codes to their colors in the colorMap
 */
const strategyCodeToColor = computed(() => {
  const retval: { [strategyCode: string]: string } = {};
  for (const [, item] of Object.entries(colorMap.value)) {
    if (item.strategyCode) {
      retval[item.strategyCode] = item.color;
    }
  }

  return retval;
});

const colors = computed((): { [name: string]: string } => {
  return SettingsModule.colors.pin || {};
});
const allColors = computed((): IColors => {
  return SettingsModule.colors || {};
});

const allPinnedColorsArray = computed((): string[] => {
  return Object.values(colors.value);
});

const firstMatrixHoveredItemId = computed((): string | null => {
  return PinnedStore.firstMatrixHoveredItemId;
});

const secondMatrixHoveredItemId = computed((): string | null => {
  return PinnedStore.secondMatrixHoveredItemId;
});

const itemUnderAnalysis = computed(() => {
  return AnalyticsStore.itemUnderAnalysis;
});

const clearPinned = async (): Promise<void> => {
  nextPinnedColorIndex.value = 0;
  await PinnedStore.SetColorMap({});
};

const waitForColors = async () => {
  if (allPinnedColorsArray.value.length) {
    // Already in good shape
    return;
  }

  await until(allPinnedColorsArray).changed({ timeout: 3 * 1000 });
};

const getNextPinnedColor = async (): Promise<string> => {
  if (!allPinnedColorsArray.value.length) {
    await waitForColors();
  }
  if (allPinnedColorsArray.value[nextPinnedColorIndex.value + 1]) {
    nextPinnedColorIndex.value += 1;
  } else nextPinnedColorIndex.value = 0;
  return allPinnedColorsArray.value[nextPinnedColorIndex.value];
};

const getColorFromType = (type?: PinItemType) => {
  if (type === PinItemType.BENCHMARK || type === PinItemType.PORTFOLIO_INDEX) return ApplicationDefaults.colors.Info;

  if (type === PinItemType.ACTIVE_RETURN) return ApplicationDefaults.colors.ActiveReturn;

  return undefined;
};

/**
 * To be used when pinning an item that is strictly not involved with the portfolio tree
 * @param keys
 * @param forceColor
 */
const pin = async ({
  keys,
  forceColor,
  type,
}: {
  keys: string[];
  forceColor?: string;
  type?: PinItemType;
}): Promise<void> => {
  const color = forceColor || getColorFromType(type) || (await getNextPinnedColor());

  const pinFn = (key: string, type?: PinItemType) => {
    const entryWithTypeAlreadyExists = Object.entries(colorMap.value).find(([, entry]) => entry.type === type);

    if (type && entryWithTypeAlreadyExists) {
      del(colorMap.value, entryWithTypeAlreadyExists[0]);
    }

    // TODO: consider taking this logic out. doesn't seem necessary??
    if (
      colorMap.value[key] &&
      itemUnderAnalysis.value &&
      isPortfolioTreeSubportfolio(itemUnderAnalysis.value) &&
      itemUnderAnalysis.value.portfolioTreeId === key
    ) {
      const copy = cloneDeep(colorMap.value[key]);
      set(colorMap.value, key, {
        ...copy,
        color,
        thickness: 1,
        type,
      });
      return;
    }

    set(colorMap.value, key, { color, thickness: 1, type });
  };

  for (const key of keys) {
    pinFn(key, type);
  }
};

/**
 * To be used when pinning an item that is involved with the portfolio tree
 * This will use the color of the existing item in the colormap, so that an item retains its colour
 * @param selfKey
 * @param children
 * @param subportfolioToRecurse
 */
const pinPortfolioTreeItem = async ({
  selfKey,
  selfCode,
  forceColor,
  children = [],
  subportfolioToRecurse,
}: {
  selfKey: string;
  selfCode?: string;
  forceColor?: string;
  children?: { key: string; strategyCode?: string }[];
  subportfolioToRecurse?: IPortfolioTreeSubportfolio;
}): Promise<void> => {
  /**
   * First, if there is a strategy code, see if it is already pinned, and if so, then use that color
   */
  const strategyColor = selfCode !== undefined ? strategyCodeToColor.value[selfCode] : undefined;

  const colorToUse = forceColor ?? strategyColor ?? colorMap.value[selfKey]?.color;
  set(colorMap.value, selfKey, {
    strategyCode: selfCode,
    color: colorToUse ?? (await getNextPinnedColor()),
    thickness: 1,
  });

  for (const childKey of children) {
    await pinPortfolioTreeItem({ selfKey: childKey.key, selfCode: childKey.strategyCode });
  }

  if (subportfolioToRecurse) {
    const descendants = getAllDescendantIds(subportfolioToRecurse);
    for (const descendant of descendants) {
      await pinPortfolioTreeItem({ selfKey: descendant.key, selfCode: descendant.strategyCode });
    }
  }
};

/**
 * To be used when pinning the item under analysis
 * The parent key will be the item under analysis and the descendants will color accordingly
 * @param selfKey
 * @param children
 * @param subportfolioToRecurse
 */
const pinItemUnderAnalysis = async ({
  selfKey,
  selfCode,
  children = [],
  subportfolioToRecurse,
}: {
  selfKey: string;
  selfCode?: string;
  children?: { key: string; strategyCode?: string }[];
  subportfolioToRecurse?: IPortfolioTreeSubportfolio;
}): Promise<void> => {
  set(colorMap.value, selfKey, {
    color: SettingsModule.portfolioColor,
    strategyCode: selfCode,
    thickness: 2,
  });
  for (const child of children) {
    await pinPortfolioTreeItem({ selfKey: child.key, selfCode: child.strategyCode });
  }
  if (subportfolioToRecurse) {
    const descendants = getAllDescendantIds(subportfolioToRecurse);
    for (const descendant of descendants) {
      await pinPortfolioTreeItem({ selfKey: descendant.key, selfCode: descendant.strategyCode });
    }
  }
};

const unpin = (key: string): void => {
  if (colorMap.value[key]) {
    const copy = cloneDeep(colorMap.value);
    delete copy[key];
    colorMap.value = copy;
  }
};

const setHoveredItemId = (id: string | null): void => {
  PinnedStore.SetHoveredItemId({ id: id || null });
};

export default function (): {
  hoveredItemId: ComputedRef<string | null>;
  colorMap: WritableComputedRef<PinnedMap>;
  firstMatrixHoveredItemId: ComputedRef<string | null>;
  secondMatrixHoveredItemId: ComputedRef<string | null>;
  colors: ComputedRef<{ [name: string]: string }>;
  setHoveredItemId: (id: string | null) => void;
  unpin: (key: string) => void;
  pinPortfolioTreeItem: ({
    selfKey,
    selfCode,
    forceColor,
    children,
    subportfolioToRecurse,
  }: {
    selfKey: string;
    selfCode?: string;
    forceColor?: string;
    children?: { key: string; strategyCode?: string }[];
    subportfolioToRecurse?: IPortfolioTreeSubportfolio;
  }) => Promise<void>;
  /**
   * 'selfKey' refers to the unique key that the item will be identified with,
   *
   * 'selfCode' refers to the strategy code (possibly undefined and possibly non-unique) that is part of the item
   */
  pinItemUnderAnalysis: ({
    selfKey,
    selfCode,
    children,
    subportfolioToRecurse,
  }: {
    selfKey: string;
    selfCode?: string;
    children?: { key: string; strategyCode?: string }[];
    subportfolioToRecurse?: IPortfolioTreeSubportfolio;
  }) => Promise<void>;
  pin: ({ keys, forceColor, type }: { keys: string[]; forceColor?: string; type?: PinItemType }) => Promise<void>;
  clearPinned: () => Promise<void>;
  allColors: ComputedRef<IColors>;
  strategyCodeToColor: ComputedRef<{ [strategyCode: string]: string }>;
} {
  return {
    hoveredItemId,
    colorMap,
    firstMatrixHoveredItemId,
    secondMatrixHoveredItemId,
    colors,
    setHoveredItemId,
    unpin,
    pinPortfolioTreeItem,
    pinItemUnderAnalysis,
    pin,
    clearPinned,
    allColors,
    strategyCodeToColor,
  };
}
