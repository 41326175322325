import { useQuery } from '@tanstack/vue-query';
import {
  createBlmView,
  deleteBlmView,
  getBlmViews,
  setLastUsedBlmViewForPortfolio,
  updateBlmView,
  BLMView,
  AttributeBLMParameter,
} from '@/api-v2/web/blm';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { defineMutation } from './defineMutation';
import usePremialabQuery from './usePremialabQuery';
import { chainEnabled } from '@/utils/queries';

const keys = {
  all: () => [{ scope: 'blm-view' }] as const,
  list: () => [{ ...keys.all()[0], entity: 'list' }] as const,
};

const select = {
  blmViews: (data: BLMView[]) => {
    return [...data].sort((a, b) => a.name.localeCompare(b.name));
  },
};

/**
 * Get all BLM views
 */
export function useGetBlmViews(options: VQQueryOptions = {}) {
  const enabled = chainEnabled(options.enabled);
  return usePremialabQuery(
    useQuery({
      queryKey: keys.list(),
      queryFn: () => getBlmViews(),
      ...options,
      enabled,
      select: select.blmViews,
    }),
    enabled,
  );
}

/**
 * Create a new BLM view
 */
export const useCreateBlmView = defineMutation({
  mutationFn: ({
    body,
  }: {
    body: {
      name: string;
      parameter: AttributeBLMParameter[];
    };
  }) => createBlmView(body),
  invalidateCache(client) {
    return client.invalidateQueries(keys.all());
  },
});

/**
 * Update a single BLM view
 */
export const useUpdateBlmView = defineMutation({
  mutationFn: ({ body }: { body: { attributeId: string; view: BLMView } }) => updateBlmView(body),
  invalidateCache(client, data) {
    const selectors = [keys.all(), [{ blmId: data.body.attributeId }]];
    return Promise.all(selectors.map((selector) => client.invalidateQueries(selector)));
  },
});

/**
 * Delete a single BLM view
 */
export const useDeleteBlmView = defineMutation({
  mutationFn: ({ attributeId }: { attributeId: string }) => deleteBlmView({ attributeId }),
  invalidateCache(client, data) {
    const selectors = [keys.all(), [{ blmId: data.attributeId }]];
    return Promise.all(selectors.map((selector) => client.invalidateQueries(selector)));
  },
});

/**
 * @deprecated - Sets the last used BLM view for a portfolio when there is an active valid current views
 *
 * TODO: delete this after the Market Regime feature is approved for client use
 *
 */
export const useSetLastUsedBlm = defineMutation({
  mutationFn: ({ slug, attributeId }: { slug: string; attributeId: string }) =>
    setLastUsedBlmViewForPortfolio({ slug, attributeId }),
  invalidateCache(client) {
    return client.invalidateQueries(keys.all());
  },
});
