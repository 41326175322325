import { MaybeRef, toRef } from '@vueuse/core';
import { sortBy, uniq, uniqBy } from 'lodash';
import { computed, Ref, ref } from 'vue';

/**
 * A `Filter` object manages the filtering of objects based on a predicate on a single column.
 *
 * Designed to be used with `SortingFilteringButton` and `b-table`.
 */
export interface Filter<T> {
  selectedItems: Ref<T[]>;
  availableItems: Ref<{ label: string; value: T }[]>;
  select: (items: T[]) => void;
}

/**
 * Returns a `Filter` object based on a specific key on a set of rows
 *
 * @param rows List of rows
 * @param key Key to generate a filter on
 * @param translationFn Optional function to translate values into labels
 */
export function useDynamicFilter<TObject, TKey extends keyof TObject>(
  rows: Ref<TObject[] | undefined>,
  key: TKey,
  translationFn?: (value: TObject[TKey]) => string,
): Filter<TObject[TKey]>;
/**
 * Returns a `Filter` object based on a specific key on a set of rows
 *
 * @param rows List of rows
 * @param translationFn Function to generate labels and values for filtering
 */
export function useDynamicFilter<TObject, T>(
  rows: Ref<TObject[] | undefined>,
  translationFn: (row: TObject) => { label: string; value: T },
): Filter<T>;
export function useDynamicFilter<TObject>(
  rows: Ref<TObject[] | undefined>,
  keyOrTranslationFn: string | ((row: TObject) => { label: string; value: unknown }),
  translationFn: (value: unknown, row?: TObject) => string = (o) => `${o}`,
): Filter<unknown> {
  if (typeof keyOrTranslationFn === 'string') {
    const availableItems = computed(() => {
      const values = uniq((rows.value ?? []).map((o) => (o as Record<string, unknown>)[keyOrTranslationFn])).sort();
      return values.map((o) => ({ label: o != null ? translationFn(o) : ``, value: o }));
    });
    return useFixedFilter(availableItems);
  }

  const availableItems = computed(() => {
    return sortBy(uniqBy((rows.value ?? []).map(keyOrTranslationFn), 'value'), 'label');
  });
  return useFixedFilter(availableItems);
}

/**
 * Returns a `Filter` object based on a pre-defined list of fixed values.
 *
 * Designed to be used with `SortingFilteringButton` component and `b-table`.
 *
 * @param items List of values to be used as filters
 */
export const useFixedFilter = <T>(items: MaybeRef<{ label: string; value: T }[]>): Filter<T> => {
  // HACK: Force a cast here as using ref<T[]>([]) causes the error
  // `Type 'Ref<UnwrapRefSimple<T>[]>' is not assignable to type 'Ref<T[]>'.`
  const selectedItems = ref<T[]>([]) as Ref<T[]>;
  const availableItems = toRef(items);

  return {
    selectedItems,
    availableItems,
    select: (items: T[]) => {
      selectedItems.value = items;
    },
  };
};

type WidenEnum<T> = T extends string ? string : T;

/**
 * Chain applies filters on an array
 */
export class ChainedFilter<TObject> {
  private rows: TObject[];

  constructor(rows: TObject[]) {
    this.rows = [...rows];
  }

  public filter(predicate: (value: TObject) => boolean) {
    this.rows = this.rows.filter(predicate);
    return this;
  }

  public withDeleted(showDeletedItems: boolean) {
    if (!showDeletedItems) {
      this.rows = this.rows.filter((row) => (row as { deletedAt?: string | null }).deletedAt == null);
    }
    return this;
  }

  public useFilter<TKey extends keyof TObject & string, T extends TObject[TKey]>(
    key: TKey,
    filter: Filter<T>,
    filterEnabled?: boolean,
  ): ChainedFilter<TObject>;
  public useFilter<TKey extends keyof TObject & string, T extends TObject[TKey]>(
    key: TKey,
    filter: Filter<NonNullable<T>>,
    filterEnabled?: boolean,
  ): ChainedFilter<TObject>;
  public useFilter<TKey extends keyof TObject & string, T extends TObject[TKey]>(
    key: TKey,
    filter: Filter<WidenEnum<T>>,
    filterEnabled?: boolean,
  ): ChainedFilter<TObject>;
  public useFilter<TKey extends keyof TObject & string, T extends TObject[TKey]>(
    key: TKey,
    filter: Filter<NonNullable<WidenEnum<T>>>,
    filterEnabled?: boolean,
  ): ChainedFilter<TObject>;
  public useFilter<TKey extends keyof TObject & string>(
    key: TKey,
    filter: Filter<TObject[TKey]>,
    filterEnabled: boolean = true,
  ): ChainedFilter<TObject> {
    // Show all items if no items are selected
    if (filter.selectedItems.value.length === 0) {
      return this;
    }

    // Show all items if the filter is not enabled
    if (!filterEnabled) {
      return this;
    }

    // Filter by the selected items
    // TObject[TKey] may or may not include null or undefined. Widen it anyways so the `.has()` check works.
    const selectedItemsSet = new Set(filter.selectedItems.value) as Set<TObject[TKey] | null | undefined>;
    this.rows = this.rows.filter((row) => selectedItemsSet.has(row[key]));
    return this;
  }

  public result() {
    return this.rows;
  }

  public static from<TObject>(rows: TObject[] | undefined) {
    return new ChainedFilter(rows ?? []);
  }
}
