import { createGlobalState } from '@vueuse/shared';
import { ref, watch } from 'vue';
import { useIndexUniqueIdentifier } from '../useCorrectIdentifier';

/**
 * Please refer to IPortfolioTreeDraftParams in src/types/IPortfolioTree.ts for detailed explanation.
 */
export const useBasketSnapshot = createGlobalState(() => {
  const indexUniqueIdentifier = useIndexUniqueIdentifier();

  /**
   * Default value is true on the api side.
   */
  const shouldShowBasketSnapshot = ref(true);

  /**
   * It should be set reset before visiting factsheet/ switching portfolio
   */
  const resetShouldShowBasketSnapshot = () => {
    shouldShowBasketSnapshot.value = true;
  };

  /**
   * Reset the shouldShowBasketSnapshot when the portfolio changes
   */
  watch(
    indexUniqueIdentifier,
    () => {
      resetShouldShowBasketSnapshot();
    },
    { immediate: true },
  );

  return {
    shouldShowBasketSnapshot,
    resetShouldShowBasketSnapshot,
  };
});
