import { UserPermission } from '@/constants/UserPermission';
import { UserModule } from '@/store/barrel';
import { IUserInfoResponse } from '@/api-v2/web/user';
import { computed } from 'vue';

/**
 * If possible, prefer {@link useUserQuery} for getting the user object instead.
 */
export default function () {
  const user = computed((): IUserInfoResponse | null => UserModule.user);

  const isUserAdmin = computed((): boolean => {
    if (!user.value || !user.value.permission) return false;
    return user.value.permission.includes(UserPermission.ADMIN);
  });

  const isUserImpersonating = computed((): boolean => {
    if (!user.value || !user.value.permission) return false;
    return user.value.permission.includes(UserPermission.IMPERSONATE);
  });

  const isShowUserImpersonatingBanner = computed((): boolean => {
    if (!user.value || !user.value.permission) return false;
    return user.value.permission.includes(UserPermission.WARN_IMPERSONATE);
  });

  return {
    user,
    isUserAdmin,
    isUserImpersonating,
    isShowUserImpersonatingBanner,
  };
}
