<!-- eslint-disable vue/no-lone-template -->
<template>
  <b-dropdown
    id="export-portfolio"
    ref="exportDropdown"
    variant="outline-primary"
    :text="translate({ path: 'GLOBAL.EXPORT' })"
    class="w-20"
    lazy
    offset="1"
    right
    @hide="onHide"
    @show="preventDropdownClose = true"
    @click="onParentDropdownClick"
  >
    <b-dropdown-form style="width: 25rem">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.NAME' })"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
          label-for="export-portfolio-name"
        >
          <b-form-input
            id="export-portfolio-name"
            v-model="newExportedSubportfolioName"
            :state="newExportedSubportfolioName.length == 0 || isTrackNameInvalid ? false : null"
            :placeholder="subportfolioToBeExported.name"
          />
          <b-form-invalid-feedback
            v-if="isTrackNameInvalid"
            id="export-private-track-name-feedback"
          >
            {{ translate({ path: 'FORM.UNIQUE_NAME' }) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback id="export-portfolio-name-feedback">
            {{ translate({ path: 'FORM.REQUIRED_FIELD' }) }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.EXPORT_AS' })"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
          label-for="export-portfolio-type"
        >
          <!-- TODO: dropdown options should have icons -->
          <StandardFormDropdown>
            <template #button-content>
              {{ translate({ path: 'MODALS.STATIC_DATA_MODAL.EXPORT_TYPE', item: exportType }) }}
            </template>
            <template>
              <b-dropdown-item
                v-for="option in exportTypesOptions"
                :key="option"
                :active="exportType === option"
                @click.stop="exportType = option"
              >
                {{ option }}
              </b-dropdown-item>
            </template>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.ADD_TO' })"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
          label-for="export-portfolio-destination"
        >
          <StandardFormDropdown
            id="export-portfolio-destination"
            :disabled="!isPortfolioListEnabled"
          >
            <template #button-content>
              <span
                v-if="isPortfolioListEnabled"
                class="text-ellipsis"
              >
                {{ selectedPortfolio?.name ?? translate({ path: 'MODALS.GENERAL.SELECT_PORTFOLIO' }) }}
              </span>
              <span
                v-else
                class="text-ellipsis"
              >
                -
              </span>
            </template>
            <template>
              <b-form-group class="px-2 mb-0">
                <ClearableFormInput
                  ref="searchBox"
                  :search-query.sync="searchBoxQuery"
                  :size="'sm'"
                  :placeholder="translate({ path: 'GLOBAL.SEARCH' })"
                />
              </b-form-group>
              <b-dropdown-divider class="px-2" />
              <b-form-group class="scroll-md mb-0">
                <b-dropdown-item
                  v-for="portfolioOption of portfolios"
                  :id="portfolioOption.slug"
                  :key="portfolioOption.slug"
                  :active="selectedPortfolio?.slug === portfolioOption.slug"
                  :title="portfolioOption.name"
                  @click.stop="selectedPortfolio = portfolioOption"
                >
                  {{ portfolioOption.name }}
                </b-dropdown-item>
              </b-form-group>
            </template>
          </StandardFormDropdown>
          <b-form-invalid-feedback
            v-if="isPortfolioListEnabled"
            id="export-portfolio-destination-feedback"
            :state="isPortfolioDestinationSelected ? null : false"
          >
            {{ translate({ path: 'FORM.REQUIRED_FIELD' }) }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <div class="d-flex justify-content-between align-items-center px-3">
        <b-button
          class="px-5"
          @click="onCancelButtonClicked"
        >
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </b-button>
        <b-overlay
          :show="busyExporting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="info"
        >
          <b-button
            class="px-5"
            variant="info"
            :disabled="isExportSubportfolioButtonDisabled"
            data-testid="export-portfolio"
            @click="exportPortfolio"
          >
            {{ translate({ path: 'GLOBAL.EXPORT' }) }}
          </b-button>
        </b-overlay>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script lang="ts">
import useTranslation from '@/composables/useTranslation';
import { defineComponent, computed, ref, PropType, watch } from 'vue';
import StandardFormDropdown from '@/components/standard-components/StandardFormDropdown.vue';
import { IPortfolioTreeSubportfolio, isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { BDropdown, BvEvent } from 'bootstrap-vue';
import { useAppendPortfolioTree } from '@/composables/queries/usePortfolioTreeData';
import usePeriod from '@/composables/usePeriod';
import { IPortfolioTreeTracksQuery } from '@/types/IPortfolioTreeTracksQuery';
import { IPortfolioTreeTrackResponse } from '@/types/IPortfolioTreeTracksResponse';
import { PortfolioType } from '@/types/portfolio';
import { useToasts } from '@/composables/useToasts';
import ClearableFormInput from '@/components/standard-components/ClearableFormInput.vue';
import { useRouter } from '@/composables/useRouter';
import { RouteName } from '@/constants/RouteName';
import { onClickOutside, templateRef } from '@vueuse/core';
import { getPortfolioTreeBySlug, getPortfolioTreeTrack } from '@/api-v2/web/portfolio-trees';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { useUploadStrategy } from '@/composables/queries/useStrategies';
import { useAllPortfolios, useAllStrategies } from '@/composables/queries/useDataDiscovery';
import { TrackTypeConstants } from '@/constants/TrackTypeConstants';
import { getClonedName, sortPortfolios } from '@/utils/portfolio';
import { PortfolioItemResponseDTO } from '@/api-v2/web/discover';
import usePortfolioTree from '@/composables/usePortfolioTree';
import useAppMetrics from '@/composables/useAppMetrics';
import { TRACKING_BUTTON_NAME } from '@/types/analytics';
import { usePortfolioTreeDraftIdentifiers } from '@/composables/useCorrectIdentifier';
import { notNull } from '@/utils/notnull';

const { trackButtonClick } = useAppMetrics();

enum PortfolioTypes {
  TREE = 'Tree',
  PRIVATE_TRACK = 'Private Track',
}

export default defineComponent({
  name: 'ExportPortfolioDropdown',
  components: { StandardFormDropdown, ClearableFormInput },
  props: {
    subportfolioToBeExported: {
      type: Object as PropType<IPortfolioTreeSubportfolio>,
      required: true,
    },
  },
  setup(props) {
    const { translate } = useTranslation();
    const { successToast, errorToast } = useToasts();
    const { masterPortfolioTree } = usePortfolioTree();
    const { draftSlug } = usePortfolioTreeDraftIdentifiers();
    const newExportedSubportfolioName = ref(props.subportfolioToBeExported.name);

    // update newExportedSubportfolioName when user changes the subportfolio to be exported
    watch(
      () => props.subportfolioToBeExported,
      (newValue) => {
        newExportedSubportfolioName.value = newValue.name;
      },
    );

    /**
     * Declaring portfolio export types
     * */
    const exportType = ref(PortfolioTypes.TREE);
    const exportTypesOptions: PortfolioTypes[] = [PortfolioTypes.TREE, PortfolioTypes.PRIVATE_TRACK];

    // no need to sort because we never see the strategies
    const allStrategies = useAllStrategies();
    const forbiddenTrackNames = computed(
      () =>
        new Set(
          (allStrategies.data.value ?? [])
            .filter((o) => o.trackType === TrackTypeConstants.PRIVATE_TRACK)
            .map((o) => o.shortName?.trim())
            .filter(notNull),
        ),
    );

    const isTrackNameInvalid = computed(
      () =>
        exportType.value === PortfolioTypes.PRIVATE_TRACK &&
        forbiddenTrackNames.value.has(newExportedSubportfolioName.value.trim()),
    );

    const isExportSubportfolioButtonDisabled = computed(() => {
      if (exportType.value === PortfolioTypes.TREE) {
        if (isPortfolioListEnabled.value && !selectedPortfolio.value) return true;
      }
      if (isTrackNameInvalid.value) return true;
      if (!newExportedSubportfolioName.value) return true;

      return false;
    });

    /**
     * Manages dropdown events for export portfolio button
     * The events on the button and the forms inside affected each other because they are both dropdowns
     * */
    const preventDropdownClose = ref(false);
    const exportDropdown = ref<BDropdown | null>(null);
    const exportDropdownContainer = templateRef<HTMLElement>('exportDropdown');
    const busyExporting = ref(false);

    const onHide = (event: BvEvent) => {
      if (preventDropdownClose.value) event.preventDefault();
    };
    const onParentDropdownClick = () => {
      preventDropdownClose.value = false;
      if (exportDropdown.value) exportDropdown.value.hide();
    };
    const onCancelButtonClicked = () => {
      preventDropdownClose.value = false;
      if (exportDropdown.value) exportDropdown.value.hide();
      busyExporting.value = false;
    };
    onClickOutside(exportDropdownContainer, () => {
      preventDropdownClose.value = false;
      busyExporting.value = false;
    });

    const searchBoxQuery = ref('');
    const selectedPortfolio = ref<PortfolioItemResponseDTO | null>(null);
    const isPortfolioDestinationSelected = computed(() => selectedPortfolio.value !== null);

    /**
     * Getting accessible portfolio list to export subportfolio to
     */
    const portfolioTrees = useAllPortfolios();

    const dataset = computed(() => {
      return (portfolioTrees.data.value ?? []).filter((o) => o.type === PortfolioType.PORTFOLIO);
    });

    const portfolios = computed(() => {
      if (searchBoxQuery.value === '') {
        return dataset.value;
      }

      return sortPortfolios(
        dataset.value.filter((y) => {
          return y.name && y.name.toLowerCase().includes(searchBoxQuery.value.toLowerCase());
        }),
      );
    });

    /**
     * Export portfolio to append on the selected portfolio if the export type is tree
     */
    const isPortfolioListEnabled = computed(() => exportType.value === PortfolioTypes.TREE);
    const router = useRouter();

    const appendPortfolio = useAppendPortfolioTree({
      onSuccess(appendPortfolio) {
        const { href } = router.resolve({
          name: RouteName.PORTFOLIO_CONSTRUCTION,
          params: { indexUniqueIdentifier: appendPortfolio.slug },
        });
        busyExporting.value = false;
        preventDropdownClose.value = false;
        if (exportDropdown.value) exportDropdown.value.hide();
        successToast({
          title: translate({
            path: 'DATA.TOASTS.ADD_SUBPORTFOLIO_TO_PORTFOLIO.SUCCESS',
          }),
          href,
        });
      },
      onError() {
        busyExporting.value = false;
        errorToast(
          translate({
            path: 'DATA.TOASTS.ADD_SUBPORTFOLIO_TO_PORTFOLIO.ERROR.GENERIC',
          }),
        );
      },
    });

    const { period, previousBusinessDay } = usePeriod();

    const query = computed((): IPortfolioTreeTracksQuery => {
      return {
        startDate: props.subportfolioToBeExported.historyStartDate?.toString() || period.value.fromDateString(),
        endDate: previousBusinessDay.value,
        returnInterval: ReturnInterval.DAILY,
        codes: [],
        treeIds: [props.subportfolioToBeExported.portfolioTreeId],
        portfolioTreeId: props.subportfolioToBeExported.portfolioTreeId,
      };
    });

    const uploadMutation = useUploadStrategy();

    /**
     * Export portfolio as a private track if the export type is private track
     */
    const savePortfolioAsTrack = async (shortName: string, trackResponse: IPortfolioTreeTrackResponse[]) => {
      try {
        let datesArray = trackResponse[0].timeseriesDates;
        let valuesArray = trackResponse[0].timeseriesEquityVariation;

        const track: [string, number][] = datesArray.map((date, index) => {
          /**
           * We multiply by 100 here because John said so
           * We add 100 here at each value because the backend expects the track to start from 100
           */
          return [date, valuesArray[index] * 100 + 100];
        });

        await uploadMutation.mutateAsync({
          shortName,
          currency: props.subportfolioToBeExported.toCurrency,
          track,
        });

        busyExporting.value = false;
        successToast({
          title: translate({
            path: 'DROP_ZONE.EXPORT_TRACK_SUCCESS',
          }),
        });
      } catch {
        busyExporting.value = false;
        errorToast(
          translate({
            path: 'DROP_ZONE.EXPORT_TRACK_FAILURE',
          }),
        );
      }
    };

    const exportPortfolio = async () => {
      busyExporting.value = true;
      if (!masterPortfolioTree.value || !draftSlug.value) return;
      // if the portfolio type is a tree, append the tree to the selected portfolio
      if (exportType.value === PortfolioTypes.TREE) {
        // if slug is not selected, return error
        if (!selectedPortfolio.value) return;

        // get the list of existing portfolio names in the destination portfolio
        const destinationPortfolio = getPortfolioTreeBySlug(selectedPortfolio.value.slug);
        const forbiddenPortfolioNames = await destinationPortfolio.then((p) =>
          p.portfolioTree.components.map((p) => (isPortfolioTreeSubportfolio(p) && p.name) || ''),
        );

        // if the portfolio name is not unique, add a suffix
        const newUniquePortfolioName = getClonedName(newExportedSubportfolioName.value, forbiddenPortfolioNames, ' -');

        const treeItemAllocation = masterPortfolioTree.value.allocation[props.subportfolioToBeExported.portfolioTreeId];
        const payload = {
          allocation: { [props.subportfolioToBeExported.portfolioTreeId]: treeItemAllocation },
          portfolioTree: { ...props.subportfolioToBeExported, name: newUniquePortfolioName },
        };

        appendPortfolio.mutate({ slug: selectedPortfolio.value.slug, payload });

        // Usage Tracking
        trackButtonClick(TRACKING_BUTTON_NAME.EXPORT_PORTFOLIO, {
          exportType: exportType.value,
          newPortfolioName: newExportedSubportfolioName.value,
          masterPortfolioId: masterPortfolioTree.value.portfolioId,
          destinationPortfolioId: selectedPortfolio.value.slug,
        });
        return;
      }

      // if the portfolio type is a private track, export the private track to myLab
      const trackResponse = await getPortfolioTreeTrack(draftSlug.value, query.value);
      savePortfolioAsTrack(newExportedSubportfolioName.value, trackResponse);

      // Usage Tracking
      trackButtonClick(TRACKING_BUTTON_NAME.EXPORT_PORTFOLIO, {
        exportType: exportType.value,
        newPortfolioName: newExportedSubportfolioName.value,
        masterPortfolioId: masterPortfolioTree.value.portfolioId,
      });
    };

    return {
      translate,
      portfolios,
      selectedPortfolio,
      exportType,
      exportTypesOptions,
      searchBoxQuery,
      onHide,
      onParentDropdownClick,
      onCancelButtonClicked,
      exportDropdown,
      preventDropdownClose,
      exportPortfolio,
      isPortfolioListEnabled,
      isPortfolioTreeSubportfolio,
      newExportedSubportfolioName,
      isExportSubportfolioButtonDisabled,
      isTrackNameInvalid,
      isPortfolioDestinationSelected,
      busyExporting,
    };
  },
});
</script>
