import { RouteName } from '@/constants/RouteName';
import { ChartTypes } from '@/types/ChartTypes';
import { PortfolioConstructionTabs } from '@/types/PortfolioConstructionTabs';
import { AnalysisSubstep, AnalysisStep } from './AnalysisStep';

export const PORTFOLIO_CONSTRUCTION_SIMULATION: AnalysisSubstep = {
  title: 'Simulation',
  graphTypes: [ChartTypes.TRACK_RECORD, ChartTypes.EXPOSURE],
  path: PortfolioConstructionTabs.SIMULATION,
};
export const PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER: AnalysisSubstep = {
  title: 'Efficient Frontier',
  graphTypes: [ChartTypes.EFFICIENT_FRONTIER],
  path: PortfolioConstructionTabs.FRONTIER,
};
export const PORTFOLIO_CONSTRUCTION_ROLLING: AnalysisSubstep = {
  title: 'Rolling Metric',
  graphTypes: [ChartTypes.ROLLING_VOLATILITY, ChartTypes.ROLLING_SHARPE],
  path: PortfolioConstructionTabs.ROLLING,
};
export const PORTFOLIO_CONSTRUCTION_PERF_ATTRIB: AnalysisSubstep = {
  title: 'Performance Contribution',
  graphTypes: [
    ChartTypes.PERFORMANCE_ATTRIBUTION_GROUP_BY,
    ChartTypes.CUMULATIVE_MONTHLY_RETURN_BREAKDOWN,
    ChartTypes.RANKING_TABLE,
  ],
  path: PortfolioConstructionTabs.PERFORMANCE_ATTRIBUTION,
};
export const PORTFOLIO_CONSTRUCTION_CORRELATION: AnalysisSubstep = {
  title: 'Correlation',
  graphTypes: [
    ChartTypes.ROLLING_CORRELATION,
    ChartTypes.CORRELATION,
    ChartTypes.RETURN_REGRESSION,
    ChartTypes.CORRELATION_MATRIX,
    ChartTypes.MST,
  ],
  path: PortfolioConstructionTabs.CORRELATION,
};

export const PORTFOLIO_CONSTRUCTION_RECONCILIATION: AnalysisSubstep = {
  title: 'Reconciliation',
  graphTypes: [ChartTypes.CUMULATIVE_DRIFT, ChartTypes.RECONCILIATION_TABLE, ChartTypes.CUMULATIVE_PNL],
  path: PortfolioConstructionTabs.RECONCILIATION,
};

export const PORTFOLIO_CONSTRUCTION: AnalysisStep = {
  name: RouteName.PORTFOLIO_CONSTRUCTION,
  route: '/portfolio-construction',
  legacyName: 'Portfolio Analysis',
  disabled: false,
  substeps: [
    PORTFOLIO_CONSTRUCTION_SIMULATION,
    PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER,
    PORTFOLIO_CONSTRUCTION_PERF_ATTRIB,
    PORTFOLIO_CONSTRUCTION_ROLLING,
    PORTFOLIO_CONSTRUCTION_CORRELATION,
    PORTFOLIO_CONSTRUCTION_RECONCILIATION,
  ],
  icon: 'chart-line',
  mobileOnly: false,
  defaultSubstepPath: PORTFOLIO_CONSTRUCTION_SIMULATION.path,
};
