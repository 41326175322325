import { del, set } from 'vue-demi';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { IPeriodState, Period } from '@/types/period';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { createDate, convertToBusiness } from '@/utils/dateUtils';
import useTranslation from '@/composables/useTranslation';
import useAnalysisDates from '@/composables/useAnalysisDates';
import { DateTime } from 'luxon';
import { DateFormat } from '@/constants/DateFormat';
import { useToasts } from '@/composables/useToasts';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

const { translate } = useTranslation();
const { savePeriodToSessionStorage, datesToTry } = useAnalysisDates();

const prevBusinessDay = convertToBusiness(createDate().minus({ days: 1 }));
const oneMonth = convertToBusiness(prevBusinessDay.minus({ months: 1 }));
const threeMonths = convertToBusiness(prevBusinessDay.minus({ months: 3 }));
const sixMonths = convertToBusiness(prevBusinessDay.minus({ months: 6 }));
const oneYear = convertToBusiness(prevBusinessDay.minus({ years: 1 }));
// YTD starts from the end of the last year. See https://premialab.atlassian.net/browse/WAA-2517.
const ytd = convertToBusiness(prevBusinessDay.startOf('year').minus({ days: 1 }));
const threeYears = convertToBusiness(prevBusinessDay.minus({ years: 3 }));
const fiveYears = convertToBusiness(prevBusinessDay.minus({ years: 5 }));
const tenYears = convertToBusiness(prevBusinessDay.minus({ years: 10 }));

const defaultPeriodAbbrev = PeriodAbbrevEnum.FIVE_Y;

/**
 * If the cached period is invalid, then this returns undefined
 */
const getPeriodFromCache = (periods: { [key: string]: Period }) => {
  const analysisPeriod = datesToTry.value;
  const endDateToUse = analysisPeriod.useToday === true ? prevBusinessDay : analysisPeriod.endDate || prevBusinessDay;

  const isAnalysisPeriodValid =
    analysisPeriod.startDate !== undefined && createDate(analysisPeriod.startDate) > endDateToUse;
  if (!isAnalysisPeriodValid) return;

  if (analysisPeriod.period && periods[analysisPeriod.period]) {
    return periods[analysisPeriod.period];
  }
  if (analysisPeriod.startDate) {
    return new Period({
      fromDate: createDate(analysisPeriod.startDate),
      toDate: analysisPeriod.endDate ? createDate(analysisPeriod.endDate) : prevBusinessDay,
      abbrev: PeriodAbbrevEnum.CUST,
    });
  }
  return periods[defaultPeriodAbbrev];
};

/**
 * Find the first period that is after the disabledBeforeDate
 *
 * We prefer the 5Y period if possible
 * If it tries to choose the 1M period, we return the Live date instead
 */
export const getValidPeriod = (params: {
  disabledBeforeDate: string;
  periods: { [key: string]: Period };
  sortedPeriods: Period[];
}): Period | undefined => {
  const { isFauxWhitelabel, fauxWhitelabelConfig } = useFeatureFlag();

  const firstDate = createDate(params.disabledBeforeDate);

  const firstValidPeriod = params.sortedPeriods.find((p): boolean => p.fromDate >= firstDate);
  if (!firstValidPeriod) {
    return;
  }

  const isCurrentPeriodInvalid = firstValidPeriod.fromDate >= createDate(params.disabledBeforeDate);
  if (!isCurrentPeriodInvalid) return;

  if (params.periods) {
    // prefer user defined default if it exists
    if (
      isFauxWhitelabel.value &&
      fauxWhitelabelConfig.value?.defaultPeriod &&
      params.periods[fauxWhitelabelConfig.value.defaultPeriod] &&
      params.periods[fauxWhitelabelConfig.value.defaultPeriod].fromDate >= firstDate
    ) {
      return params.periods[fauxWhitelabelConfig.value.defaultPeriod];
    }

    // otherwise prefer 5Y period
    const fiveYearPeriod = params.periods[PeriodAbbrevEnum.FIVE_Y];
    if (fiveYearPeriod !== undefined && fiveYearPeriod.fromDate >= firstDate) {
      return params.periods[PeriodAbbrevEnum.FIVE_Y];
    }

    // but if the first valid period is 1M, we use Live instead
    if (firstValidPeriod.abbrev === PeriodAbbrevEnum.ONE_M && params.periods[PeriodAbbrevEnum.LIVE]) {
      return params.periods[PeriodAbbrevEnum.LIVE];
    }
  }

  // otherwise return the first valid period
  return firstValidPeriod;
};

@Module({ dynamic: true, store, name: 'Period' })
class PeriodModuleClass extends VuexModule implements IPeriodState {
  public periods: { [key in PeriodAbbrevEnum]?: Period } = {
    [PeriodAbbrevEnum.ONE_M]: new Period({
      fromDate: oneMonth,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.ONE_M,
    }),
    [PeriodAbbrevEnum.SIX_M]: new Period({
      fromDate: sixMonths,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.SIX_M,
    }),
    [PeriodAbbrevEnum.ONE_Y]: new Period({
      fromDate: oneYear,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.ONE_Y,
    }),
    [PeriodAbbrevEnum.THREE_Y]: new Period({
      fromDate: threeYears,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.THREE_Y,
    }),
    [PeriodAbbrevEnum.FIVE_Y]: new Period({
      fromDate: fiveYears,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.FIVE_Y,
    }),
    [PeriodAbbrevEnum.TEN_Y]: new Period({
      fromDate: tenYears,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.TEN_Y,
    }),
    [PeriodAbbrevEnum.YTD]: new Period({
      fromDate: ytd,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.YTD,
    }),
  };

  public previousBusinessDay: DateTime = prevBusinessDay;

  public disabledBeforeDate = '';
  public disabledAfterDate = '';

  public uncommonPeriods: { [key: string]: Period } = {
    [PeriodAbbrevEnum.YTD]: new Period({
      fromDate: ytd,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.YTD,
    }),
    [PeriodAbbrevEnum.THREE_M]: new Period({
      fromDate: threeMonths,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.THREE_M,
    }),
  };

  /**
   * Get the period from the cache, or initialize it to an invalid period
   *
   * It will then be overwritten to a valid period once the item under analysis
   * has been set
   */
  public currentPeriod: Period =
    getPeriodFromCache(this.periods) ||
    new Period({
      fromDate: prevBusinessDay,
      toDate: prevBusinessDay,
      abbrev: PeriodAbbrevEnum.CUST,
    });

  /**
   * This flag will be set to false if the History Start Date for the period is set to
   * the previous business day or after. In this case, the date picker will
   * be disabled.
   *
   * Initialize it to whether or not the fromDate is before the previous biz day
   */
  public isPeriodValid = this.currentPeriod.fromDate < prevBusinessDay;

  public currentReturnInterval: ReturnInterval = ReturnInterval.DAILY;

  public returnIntervals: ReturnInterval[] = Object.values(ReturnInterval);

  /**
   * Sorted periods from max to min like (10y, 5y ....)
   */
  public get sortedPeriods(): Period[] {
    return Object.values(this.periods).sort((a, b): number => (a.fromDate < b.fromDate ? -1 : 1));
  }

  public get availablePeriods(): Period[] {
    // No disabled before date is set. Return an empty array.
    if (!this.disabledBeforeDate) {
      return [];
    }

    const retval: Period[] = [];

    for (const period of this.sortedPeriods) {
      if (
        ![PeriodAbbrevEnum.ONE_M].includes(period.abbrev) &&
        (period.fromDateString() > this.disabledBeforeDate || period.abbrev === PeriodAbbrevEnum.MAX)
      ) {
        retval.push(period);
      }
    }
    return retval;
  }

  @Mutation
  private SET_DISABLED_BEFORE_DATE(date: string): void {
    this.disabledBeforeDate = date;
  }

  /**
   * Set triggerWarning to true if you want to display a message to the user
   * that the analysis dates have changed
   *
   * This function will ALSO set the period if the disabledBeforeDate invalidates
   * the current period (i.e., the new disabledBeforeDate is after the current analysis fromDate)
   */
  @Action({ rawError: true })
  public async SetDisabledBeforeDate({
    date,
    triggerWarning,
  }: {
    date: string;
    triggerWarning?: boolean;
  }): Promise<void> {
    const { warningToast } = useToasts();

    const previousMaxPeriod = {
      ...this.periods[PeriodAbbrevEnum.MAX],
    };

    if (createDate(date) >= prevBusinessDay) {
      this.SET_PERIOD_IS_VALID(false);
      if (triggerWarning) {
        warningToast(translate({ path: 'ERROR.TIME_PERIOD_INVALID' }));
      }
      return;
    }

    this.SET_DISABLED_BEFORE_DATE(date);
    this.SET_MAX_PERIOD(date);

    /**
     * If the period was previously set as invalid, then we need to set a valid period
     */
    if (!this.isPeriodValid) {
      const period = getValidPeriod(this);
      if (period) {
        this.SetPeriod(period);
        this.SET_PERIOD_IS_VALID(true);
      }
      return;
    }

    const maxDate = createDate(date);

    if (this.currentPeriod.fromDate < maxDate) {
      if (triggerWarning) {
        warningToast(translate({ path: 'ERROR.TIME_PERIOD_SHORTENED' }));
      }

      await this.SetCustomPeriod({
        fromDate: maxDate,
        toDate: this.currentPeriod.toDate,
      });
      return;
    }

    /**
     * if the Max period is extended *while the Max period is selected*,
     * then we need to update the current period to read as Custom
     */
    if (
      previousMaxPeriod.fromDate &&
      maxDate < previousMaxPeriod.fromDate &&
      this.currentPeriod.abbrev === PeriodAbbrevEnum.MAX
    ) {
      await this.SetCustomPeriod({
        fromDate: this.currentPeriod.fromDate,
        toDate: this.currentPeriod.toDate,
      });
    }
  }

  @Mutation
  private SET_DISABLED_AFTER_DATE(date: string): void {
    this.disabledAfterDate = date;
  }

  @Action({ rawError: true })
  public async SetDisabledAfterDate(date: string): Promise<void> {
    this.SET_DISABLED_AFTER_DATE(date);
  }

  @Mutation
  private SET_CURRENT_RETURN_INTERVAL(returnInterval: ReturnInterval): void {
    this.currentReturnInterval = returnInterval;
  }

  @Action({ rawError: true })
  public async SetReturnInterval(returnInterval: ReturnInterval): Promise<void> {
    this.SET_CURRENT_RETURN_INTERVAL(returnInterval);
  }

  @Mutation
  private SET_LIVE_PERIOD(fromDateString: string | null): void {
    // IMPORTANT NOTE: this period is special because it's different for each
    // strategy or portfolio. Therefore the metrics for this period are NOT calculated
    // by the database on any sort of regular basis. It must be treated as CUSTOM
    // i.e., it will use the strategyDetails API, NOT the strategyInfo api

    // If the live date is not available, remove the live period
    if (!fromDateString) {
      del(this.periods, PeriodAbbrevEnum.LIVE);
      return;
    }

    set(
      this.periods,
      PeriodAbbrevEnum.LIVE,
      new Period({
        fromDate: convertToBusiness(createDate(fromDateString)),
        toDate: prevBusinessDay,
        abbrev: PeriodAbbrevEnum.LIVE,
      }),
    );
  }

  /**
   * @param date new date for the Live Period to use
   */
  @Action({ rawError: true })
  public async SetLivePeriod({ date }: { date: string | null; suppressErrors?: boolean }): Promise<void> {
    this.SET_LIVE_PERIOD(date);
  }

  @Mutation
  private SET_MAX_PERIOD(fromDateString: string): void {
    // IMPORTANT NOTE: this period is special because it's different for each
    // strategy or portfolio. Therefore the metrics for this period are NOT calculated
    // by the database on any sort of regular basis. It must be treated as CUSTOM
    // i.e., it will use the strategyDetails API, NOT the strategyInfo api
    set(
      this.periods,
      PeriodAbbrevEnum.MAX,
      new Period({
        fromDate: convertToBusiness(createDate(fromDateString)),
        toDate: prevBusinessDay,
        abbrev: PeriodAbbrevEnum.MAX,
      }),
    );
  }

  @Mutation
  private SET_CURRENT_PERIOD(period: Period): void {
    const isAnalysisPeriodValid = period.fromDate < period.toDate;
    if (!isAnalysisPeriodValid) return;

    savePeriodToSessionStorage({
      startDate: period.fromDateString(),
      endDate: period.toDateString(),
      useToday: period.toDateString() === prevBusinessDay.toFormat(DateFormat.YYYY_MM_DD),
      period: period.abbrev,
    });
    this.currentPeriod = period;
  }

  @Action({ rawError: true })
  public async SetPeriod(period?: PeriodAbbrevEnum | Period): Promise<void> {
    let periodToUse = null;
    if (
      period instanceof Period &&
      this.availablePeriods.includes(period) &&
      this.disabledAfterDate !== '' &&
      period.toDate <= createDate(this.disabledAfterDate)
    ) {
      this.SET_CURRENT_PERIOD(period);
      return;
    }

    periodToUse =
      period !== undefined && typeof period === 'string'
        ? this.periods[period] || this.uncommonPeriods[period] || getValidPeriod(this)
        : getValidPeriod(this);

    if (!periodToUse) {
      console.error('DID NOT GET PERIOD!!!');
      return;
    }

    this.SET_CURRENT_PERIOD(periodToUse);
  }

  @Action({ rawError: true })
  public async SetCustomPeriod(params: { fromDate: DateTime; toDate: DateTime }): Promise<void> {
    for (const period of Object.values(PeriodModule.periods)) {
      const matchesExistingPeriod =
        period.abbrev !== PeriodAbbrevEnum.CUST &&
        params.fromDate.toMillis() === period.fromDate.toMillis() &&
        params.toDate.toMillis() === period.toDate.toMillis();

      if (matchesExistingPeriod) {
        if (
          period.abbrev === PeriodAbbrevEnum.LIVE &&
          this.periods.live?.fromDateString() === this.periods.max?.fromDateString()
        ) {
          await PeriodModule.SetPeriod(this.periods.max);
          return;
        }
        await PeriodModule.SetPeriod(period.abbrev);
        return;
      }
    }

    this.SET_CURRENT_PERIOD(
      new Period({
        fromDate: convertToBusiness(params.fromDate),
        toDate: convertToBusiness(params.toDate),
        abbrev: PeriodAbbrevEnum.CUST,
      }),
    );
  }

  @Mutation
  public SET_PERIOD_IS_VALID(newVal: boolean): void {
    this.isPeriodValid = newVal;
  }
}

export const PeriodModule = getModule(PeriodModuleClass);
