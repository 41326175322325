import {
  getStrategyFactsheet,
  setStrategyAttributes,
  StrategyFactsheetResponseDTO,
  StrategyAttributesDTO,
  getStrategyIdentifiers,
  uploadStrategy,
  UploadTrackRequestDTO,
  StrategyAttachmentResponseDTO,
  getStrategyAttachment,
} from '@/api-v2/web/strategies';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useQuery } from '@tanstack/vue-query';
import { computed, Ref } from 'vue';
import { chainEnabled, unwrap } from '@/utils/queries';
import { defineMutation, useManagedMutation, VQMutationOptions } from './defineMutation';
import { StrategyIdentifiersResponseDTO } from '@/api-v2/web/strategies/types/StrategyIdentifiers';
import { StrategyAttachmentType } from '@/types/StrategyAttachment';
import usePremialabQuery from './usePremialabQuery';

const keys = {
  all: () => [{ scope: 'strategies' }] as const,
  factsheet: (query: Ref<string | null | undefined>) => [{ ...keys.all()[0], entity: 'factsheet', query }] as const,
  identifiers: (query: Ref<string[]>) => [{ ...keys.all()[0], entity: 'identifiers', query }] as const,
  attachment: (code: Ref<string | null>, type?: StrategyAttachmentType) =>
    [{ ...keys.all()[0], entity: 'attachment', code, type }] as const,
};

/**
 * Key for discovery page scope.
 *
 * Copied from useDataDiscovery. We need to invalidate some of the cache here.
 */
function discoveryAllKey() {
  return [{ scope: 'discover' }] as const;
}

/**
 * Get a map of identifiers for list of strategies.
 */
export function useStrategyIdentifiersByCode(
  query: Ref<string[]>,
  options: VQQueryOptions<Map<string, StrategyIdentifiersResponseDTO>> = {},
) {
  return useQuery({
    queryKey: keys.identifiers(query),
    queryFn: async () => {
      if (query.value.length === 0) {
        throw new Error('Query must not be empty');
      }
      const result = await getStrategyIdentifiers({ codes: query.value });
      return new Map(result.map((o) => [o.code, o]));
    },
    ...options,
    enabled: chainEnabled(
      options.enabled,
      computed(() => query.value.length > 0),
    ),
  });
}

/**
 * Get strategy factsheet response.
 */
export function useStrategyFactsheet(
  query: Ref<string | null | undefined>,
  options: VQQueryOptions<StrategyFactsheetResponseDTO> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => query.value != null),
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.factsheet(query),
      queryFn: () => getStrategyFactsheet(unwrap(query)),
      ...options,
      staleTime: Number.POSITIVE_INFINITY,
      enabled,
    }),
    enabled,
  );
}

/**
 * Set strategy attributes
 */
export function useSetStrategyAttributes(
  code: Ref<string>,
  options: VQMutationOptions<void, Error, StrategyAttributesDTO> = {},
) {
  return useManagedMutation(
    {
      mutationFn: (input: StrategyAttributesDTO) => setStrategyAttributes(code.value, input),
      invalidateCache(client) {
        return client.invalidateQueries(keys.factsheet(code));
      },
    },
    options,
  );
}

/**
 * Upload private track
 */
export const useUploadStrategy = defineMutation({
  mutationFn: (body: UploadTrackRequestDTO) => uploadStrategy(body),
  invalidateCache(client) {
    return client.invalidateQueries(discoveryAllKey());
  },
});

export function useStrategyAttachment(
  code: Ref<string | null>,
  type: StrategyAttachmentType,
  options: VQQueryOptions<StrategyAttachmentResponseDTO | null> = {},
) {
  return useQuery({
    ...options,
    enabled: chainEnabled(
      options.enabled,
      computed(() => code.value != null),
    ),
    queryKey: keys.attachment(code, type),
    queryFn: ({ signal }) => getStrategyAttachment(unwrap(code), signal, type),
  });
}
