export enum BasketStatusConstants {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  LIVE = 'LIVE',
  // Temporarily hiding the ARCHIVED status
  // ARCHIVED = 'ARCHIVED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
}

export enum BasketType {
  QIS = 'QIS',
  EQUITY = 'Equity',
}

export enum BasketEventStatusConstants {
  CREATION = 'CREATION',
  REBALANCE = 'REBALANCE',
}

export enum CashReinvestmentTypeConstants {
  /**
   * Distributed as Cash
   */
  DISTRIBUTE = 'DISTRIBUTE',

  /**
   * Reinvest at Risk Free Rate
   */
  REINVEST_AT_RFR = 'REINVEST_AT_RFR',

  /**
   * Reinvest at Dividend Stocks
   */
  REINVEST_AT_OPEN = 'REINVEST_AT_OPEN',
}

export enum MeasurementTypeConstants {
  UNIT = 'Unit',
  WEIGHTS = 'Weights',
}

export enum BasketCommentTypeConstants {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  /**
   * The COMPLIANT enum is only used for spa
   */
  COMPLIANT = 'compliant',
}
