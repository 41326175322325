<template>
  <b-form-input
    ref="input"
    v-model="rawDateText"
    type="text"
    class="form-control"
    :class="inputClass"
    placeholder="YYYY-MM-DD"
    autocomplete="off"
    :state="inputState"
    :style="`max-width: ${maxWidth}`"
    @blur="onBlur"
  />
</template>

<script lang="ts">
import { createDate, formatDate, REGEX_DATE_INPUT_FORMAT } from '@/utils/dateUtils';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { DateTime } from 'luxon';
import { templateRef } from '@vueuse/core';

export default defineComponent({
  name: 'DatePickerDateInput',
  props: {
    date: {
      type: Object as PropType<DateTime>,
      required: true,
    },
    maxWidth: {
      type: String,
      required: false,
      default: '100px',
    },
    inputClass: {
      type: String,
      required: false,
      default: 'text-center',
    },
  },
  emits: {
    'update-date': null,
  },
  setup(props, { emit }) {
    const input = templateRef<HTMLInputElement>('input');

    /**
     * Text shown in input box.
     */
    const rawDateText = ref<string | null>(null);

    watch(
      () => props.date,
      (newVal) => {
        rawDateText.value = formatDate(newVal);
      },
      { immediate: true },
    );

    /**
     * Prevents user from entering a date that does not exist or in the wrong format.
     */
    const inputState = computed(() => {
      if (rawDateText.value?.match(REGEX_DATE_INPUT_FORMAT) && createDate(rawDateText.value).isValid) return null;
      return false;
    });

    const onBlur = () => {
      if (inputState.value === false) return;
      if (rawDateText.value === formatDate(props.date)) {
        return;
      }
      emit('update-date', createDate(rawDateText.value));
    };

    const revertToPreviousValidValue = () => {
      rawDateText.value = formatDate(props.date);
    };

    return {
      rawDateText,
      inputState,
      onBlur,
      /**
       * expose the input element so that the parent component
       * can focus on it
       */
      input,
      /**
       * expose this function so that the parent component
       * can revert when necessary
       */
      revertToPreviousValidValue,
    };
  },
});
</script>
