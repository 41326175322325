/**
 * @description This composable is used to manage the risk table columns and layers.
 * It was initially handled in ConstituentRiskTable.vue but was moved here to make the code more modular
 * for reusability with the position page
 */
import {
  RiskTableTabs,
  RiskTabProperName,
  RiskTabs,
  StressTestColumnTypes,
  TableLayerConstants,
  VarGrouping,
} from '@/types/analytics/ConstituentRisk';
import { computed } from 'vue';
import { set } from 'vue-demi';
import { useConstituentRiskAnalysisSteps } from './useAnalysisSteps';
import {
  defaultAssetCols,
  defaultCommoditySensitivityCols,
  defaultCreditSensitivityCols,
  defaultEquitySensitivityCols,
  defaultExposureCols,
  defaultFXSensitivityCols,
  defaultInterestRateSensitivityCols,
  defaultTransitiveStressCols,
  PreferenceStorage,
  useRiskPreferences,
} from './useRiskPreferences';
import { useConstituentRiskUtilities } from './useConstituentRiskUtilities';
import CommonUtils from '@/utils/CommonUtils';
import { formatBasisPoints } from '@/utils/ConstituentRisk';
import { DateFormat } from '@/constants/DateFormat';
import { createDate } from '@/utils/dateUtils';
import { RiskDashboardTableComponentsConstants } from '@/types/IConstituentRisk';

const isNetGross = (val: string) => val === 'Net' || val === 'Gross';

export function useConstituentRiskTableUtilities() {
  const { activeAnalysisSubstep } = useConstituentRiskAnalysisSteps();
  const { isRPIPortfolio, riskMetrics, asset, customProperties } = useConstituentRiskUtilities();
  const { preferences } = useRiskPreferences();

  /**
   * List of columns available for the sensitivity tabs
   */
  const allSensitivityColumns = computed(() => {
    if (!riskMetrics.value) return [];
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.EQUITY:
        return riskMetrics.value.Equity.Sensitivity;
      case RiskTabs.INTEREST_RATE:
        return riskMetrics.value['Interest Rate'].Sensitivity;
      case RiskTabs.CREDIT:
        return riskMetrics.value.Credit.Sensitivity;
      case RiskTabs.FX:
        return riskMetrics.value.FX.Sensitivity;
      default:
        return riskMetrics.value.Commodity.Sensitivity;
    }
  });

  /**
   * List of columns saved for the ladder tabs
   */
  const ladderColumns = computed(() => {
    if (!riskMetrics.value) return [];
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.EQUITY:
        return preferences.value.equity.ladderRiskCols;
      case RiskTabs.INTEREST_RATE:
        return preferences.value['interest-rate'].ladderRiskCols;
      case RiskTabs.FX:
        return preferences.value.fx.ladderRiskCols;
      default:
        // Commodity
        return preferences.value.commodity.ladderRiskCols;
    }
  });

  /**
   * List of columns available for the ladder tabs
   */
  const allLadderColumns = computed(() => {
    if (!riskMetrics.value) return [];
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.EQUITY:
        return riskMetrics.value.Equity.Ladder;
      case RiskTabs.INTEREST_RATE:
        return riskMetrics.value['Interest Rate'].Ladder;
      case RiskTabs.FX:
        return riskMetrics.value.FX.Ladder;
      default:
        // Commodity
        return riskMetrics.value.Commodity.Ladder;
    }
  });

  /**
   * Changes the format of the commodity column names to be easier to read
   */
  const newCommodityColumnNames = (metricsObj: { [col: string]: number | null }) => {
    if (!metricsObj) return [];
    const keys = Object.keys(metricsObj).sort();
    const newMap = keys.map((key) => {
      if (isNetGross(key) || key === 'Spot') return key;
      return createDate(key, { allowNonBusiness: true }).toFormat(DateFormat.MMM_YY);
    });
    const idxOfGross = newMap.indexOf('Gross');
    const [gross] = newMap.splice(idxOfGross, 1);
    newMap.push(gross);

    const idxOfSpot = newMap.indexOf('Spot');
    if (idxOfSpot !== -1) {
      const [spot] = newMap.splice(idxOfSpot, 1);
      newMap.unshift(spot);
    }

    return newMap;
  };

  /**
   * List of default columns to show for the sensitivity tabs
   */
  const defaultSensitivityColumns = computed(() => {
    if (!riskMetrics.value) return [];
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.EQUITY:
        return defaultEquitySensitivityCols;
      case RiskTabs.INTEREST_RATE:
        return defaultInterestRateSensitivityCols;
      case RiskTabs.CREDIT:
        return defaultCreditSensitivityCols;
      case RiskTabs.FX:
        return defaultFXSensitivityCols;
      default:
        // Commodity
        return defaultCommoditySensitivityCols;
    }
  });

  /**
   * List of columns saved for the sensitivity tabs
   */
  const sensitivityColumns = computed(() => {
    if (!riskMetrics.value) return [];
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.EQUITY:
        return preferences.value.equity.sensitivityRiskCols;
      case RiskTabs.INTEREST_RATE:
        return preferences.value['interest-rate'].sensitivityRiskCols;
      case RiskTabs.CREDIT:
        return preferences.value.credit.sensitivityRiskCols;
      case RiskTabs.FX:
        return preferences.value.fx.sensitivityRiskCols;
      default:
        // Commodity
        return preferences.value.commodity.sensitivityRiskCols;
    }
  });

  /**
   * All columns available to be shown for each tab
   */
  const allColumnNames = computed(() => {
    if (!riskMetrics.value || !asset.value) return [];
    const assetAndCustomProperty = [...asset.value, ...customProperties.value];
    switch (paramPreferencesRiskTableMetricName.value) {
      case RiskTableTabs.ASSET:
        return assetAndCustomProperty;
      case RiskTableTabs.EXPOSURE:
        return riskMetrics.value.VaR.Exposure;
      case RiskTableTabs.VAR:
        return riskMetrics.value.VaR.VaR;
      case RiskTableTabs.TRANSITIVE_STRESS:
        return riskMetrics.value[RiskTabProperName.STRESS_TEST][StressTestColumnTypes.TRANSITIVE_STRESS];
      case RiskTableTabs.HISTORICAL_STRESS:
        return riskMetrics.value[RiskTabProperName.STRESS_TEST][StressTestColumnTypes.HISTORICAL_STRESS];
      case RiskTableTabs.SENSITIVITY:
        return allSensitivityColumns.value;
      case RiskTableTabs.LADDER:
        return allLadderColumns.value;
      case RiskTableTabs.CUSTOM:
        return assetAndCustomProperty.concat(
          Object.values(riskMetrics.value)
            .map((tab) => Object.values(tab))
            .flat(2),
        );
      default:
        return [];
    }
  });

  const assetColumnNames = computed(() =>
    preferences.value.options.assetRiskCols.length ? preferences.value.options.assetRiskCols : defaultAssetCols,
  );

  const exposureColumnNames = computed(() =>
    preferences.value.var.exposureRiskCols.length ? preferences.value.var.exposureRiskCols : defaultExposureCols,
  );

  const varColumnNames = computed(() =>
    preferences.value.var.varRiskCols.length
      ? preferences.value.var.varRiskCols
      : Object.values(VarGrouping).map((varGroup) => `Historical ${preferences.value.options.var} ${varGroup}`),
  );

  const transitiveStressColumnNames = computed(() =>
    preferences.value['stress-test'].transitiveStressRiskCols?.length
      ? preferences.value['stress-test'].transitiveStressRiskCols
      : defaultTransitiveStressCols,
  );

  const historicalStressColumnNames = computed(() => {
    if (!riskMetrics.value) return [];
    return preferences.value['stress-test'].historicalStressRiskCols.length
      ? preferences.value['stress-test'].historicalStressRiskCols
      : riskMetrics.value[RiskTabProperName.STRESS_TEST][StressTestColumnTypes.HISTORICAL_STRESS].slice(0, 10);
  });

  const customColumnNames = computed(() =>
    preferences.value.options.customRiskCols.length ? preferences.value.options.customRiskCols : defaultAssetCols,
  );

  const basisPoint = computed(() => {
    return formatBasisPoints(CommonUtils.getSignedNumber(preferences.value.options.basisPoints));
  });

  /**
   * Checks table has total column by checking the current path for specific table: IR, Credit, FX
   * This is because the columns and source of data is different for these specific tables
   */
  const isTotalColumnInTable = computed(() => {
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.INTEREST_RATE:
        return preferences.value['interest-rate'].tableMetricName === RiskTableTabs.DV01_TENOR;
      case RiskTabs.CREDIT:
        return preferences.value.credit.tableMetricName === RiskTableTabs.CS01_TENOR;
      case RiskTabs.FX:
        return preferences.value.fx.tableMetricName === RiskTableTabs.FX_DELTA;
      default:
        return false;
    }
  });

  /**
   * Checks if the current path is the IR page and the spot vol normal ladder tab
   * This is because the columns and source of data is different for that specific combination
   */
  const isIRLadderNormal = computed(() => {
    if (activeAnalysisSubstep.value?.path !== RiskTabs.INTEREST_RATE) return false;
    return preferences.value['interest-rate'].tableMetricName === RiskTableTabs.SPOT_VOL_NORMAL_LADDER;
  });

  /**
   * Checks if the table is the spot vol ladder tab
   * This is because the columns and source of data is different for these specific tables
   */
  const isLadderTable = computed(() => {
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.EQUITY:
        return preferences.value.equity.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER;
      case RiskTabs.INTEREST_RATE:
        return (
          preferences.value['interest-rate'].tableMetricName === RiskTableTabs.SPOT_VOL_BS_LADDER ||
          preferences.value['interest-rate'].tableMetricName === RiskTableTabs.SPOT_VOL_NORMAL_LADDER
        );
      case RiskTabs.FX:
        return preferences.value.fx.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER;
      case RiskTabs.COMMODITY:
        return preferences.value.commodity.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER;
      default:
        return false;
    }
  });

  /**
   * Checks if the current path is the commodity page and the sensitivity tab
   * This is because the columns and source of data is different for that specific combination
   */
  const isCommodityDeltaGamma = computed(() => {
    if (activeAnalysisSubstep.value?.path !== RiskTabs.COMMODITY) return false;
    return [RiskTableTabs.COMMODITY_DELTA, RiskTableTabs.COMMODITY_GAMMA].includes(
      preferences.value.commodity.tableMetricName,
    );
  });

  /**
   * Layers that are allowed to be selected
   */
  const allowedSegmentLayers = computed(() => {
    let layers: TableLayerConstants[] = Object.values(TableLayerConstants).filter(
      (layer) =>
        layer !== TableLayerConstants.VOLATILITY_SHOCK &&
        layer !== TableLayerConstants.COMMODITY &&
        layer !== TableLayerConstants.PORTFOLIO,
    );
    if (isCommodityDeltaGamma.value) {
      layers = layers.filter((layer) => layer !== TableLayerConstants.UNDERLYING);
      layers.push(TableLayerConstants.COMMODITY);
      layers.sort();
    }
    if (isLadderTable.value) {
      layers.push(TableLayerConstants.VOLATILITY_SHOCK);
      layers.sort();
    }

    // TODO: Update the commodity table on the API
    if (isRPIPortfolio.value && !isCommodityDeltaGamma.value) {
      layers.push(TableLayerConstants.PORTFOLIO);
      layers.sort();
    }

    return layers;
  });

  /**
   * Tab that should be active for the table on each page
   */
  const paramPreferencesRiskTableMetricName = computed(() => {
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.VAR:
        return preferences.value.var.tableMetricName;
      case RiskTabs.STRESS_TEST:
        return preferences.value['stress-test'].tableMetricName;
      case RiskTabs.EQUITY:
        return preferences.value.equity.tableMetricName;
      case RiskTabs.INTEREST_RATE:
        return preferences.value['interest-rate'].tableMetricName;
      case RiskTabs.CREDIT:
        return preferences.value.credit.tableMetricName;
      case RiskTabs.FX:
        return preferences.value.fx.tableMetricName;
      default:
        // Commodity
        return preferences.value.commodity.tableMetricName;
    }
  });

  /**
   * Generate a component name and list of fields based on:
   * if the user is admin,
   * if the user is on a certain tab or table,
   */
  const getTableComponentAndFields = (newVal: string[]) => {
    let tableComponent = RiskDashboardTableComponentsConstants.RiskTable;
    let fields: string[] = [];

    const activeSubstep = activeAnalysisSubstep.value?.path;
    const tableMetric = paramPreferencesRiskTableMetricName.value;

    switch (tableMetric) {
      case RiskTableTabs.DV01_TENOR:
        tableComponent = RiskDashboardTableComponentsConstants.IRTable;
        break;
      case RiskTableTabs.CS01_TENOR:
        tableComponent = RiskDashboardTableComponentsConstants.CreditTable;
        break;
      case RiskTableTabs.FX_DELTA:
        tableComponent = RiskDashboardTableComponentsConstants.FXTable;
        break;
      case RiskTableTabs.COMMODITY_DELTA:
        tableComponent = RiskDashboardTableComponentsConstants.CommodityTableDelta;
        break;
      case RiskTableTabs.COMMODITY_GAMMA:
        tableComponent = RiskDashboardTableComponentsConstants.CommodityTableGamma;
        break;
      case RiskTableTabs.SPOT_VOL_LADDER:
        if (activeSubstep === RiskTabs.EQUITY) {
          tableComponent = RiskDashboardTableComponentsConstants.EquityLadderTable;
        }
        if (activeSubstep === RiskTabs.FX) {
          tableComponent = RiskDashboardTableComponentsConstants.FXLadderTable;
        }
        if (activeSubstep === RiskTabs.COMMODITY) {
          tableComponent = RiskDashboardTableComponentsConstants.CommodityLadderTable;
        }
        break;
      case RiskTableTabs.SPOT_VOL_NORMAL_LADDER:
        tableComponent = RiskDashboardTableComponentsConstants.IRNormalLadderTable;
        break;
      case RiskTableTabs.SPOT_VOL_BS_LADDER:
        tableComponent = RiskDashboardTableComponentsConstants.IRBsLadderTable;
        break;
      default:
        fields = newVal;
        break;
    }

    return { tableComponent, fields };
  };

  /**
   * Tabs to show for the table on each page
   */
  const riskMetricTabs = computed(() => {
    switch (activeAnalysisSubstep.value?.path) {
      case RiskTabs.VAR:
        return [[RiskTableTabs.ASSET], [RiskTableTabs.VAR], [RiskTableTabs.EXPOSURE], [RiskTableTabs.CUSTOM]];
      case RiskTabs.STRESS_TEST:
        return [
          [RiskTableTabs.ASSET],
          [RiskTableTabs.TRANSITIVE_STRESS],
          [RiskTableTabs.HISTORICAL_STRESS],
          [RiskTableTabs.CUSTOM],
        ];
      case RiskTabs.EQUITY:
        return [
          [RiskTableTabs.ASSET],
          [RiskTableTabs.SENSITIVITY],
          [RiskTableTabs.LADDER, RiskTableTabs.SPOT_VOL_LADDER],
          [RiskTableTabs.CUSTOM],
        ];
      case RiskTabs.INTEREST_RATE:
        return [
          [RiskTableTabs.ASSET],
          [RiskTableTabs.DV01_TENOR, RiskTableTabs.SENSITIVITY],
          [RiskTableTabs.LADDER, RiskTableTabs.SPOT_VOL_NORMAL_LADDER, RiskTableTabs.SPOT_VOL_BS_LADDER],
          [RiskTableTabs.CUSTOM],
        ];
      case RiskTabs.CREDIT:
        return [[RiskTableTabs.ASSET], [RiskTableTabs.CS01_TENOR, RiskTableTabs.SENSITIVITY], [RiskTableTabs.CUSTOM]];
      case RiskTabs.FX:
        return [
          [RiskTableTabs.ASSET],
          [RiskTableTabs.FX_DELTA, RiskTableTabs.SENSITIVITY],
          [RiskTableTabs.LADDER, RiskTableTabs.SPOT_VOL_LADDER],
          [RiskTableTabs.CUSTOM],
        ];
      default:
        // Commodity
        return [
          [RiskTableTabs.ASSET],
          [RiskTableTabs.COMMODITY_DELTA, RiskTableTabs.COMMODITY_GAMMA, RiskTableTabs.SENSITIVITY],
          [RiskTableTabs.LADDER, RiskTableTabs.SPOT_VOL_LADDER],
          [RiskTableTabs.CUSTOM],
        ];
    }
  });

  const shouldShowColumnSelectorForRisk = computed(() => {
    return ![
      RiskTableTabs.COMMODITY_DELTA,
      RiskTableTabs.COMMODITY_GAMMA,
      RiskTableTabs.CS01_TENOR,
      RiskTableTabs.DV01_TENOR,
      RiskTableTabs.FX_DELTA,
      RiskTableTabs.SPOT_VOL_LADDER,
      RiskTableTabs.SPOT_VOL_NORMAL_LADDER,
      RiskTableTabs.SPOT_VOL_BS_LADDER,
    ].includes(paramPreferencesRiskTableMetricName.value);
  });

  /**
   * Store value needed to save the list columns in the correct place
   */
  const storeValueToSave = computed(() => {
    switch (paramPreferencesRiskTableMetricName.value) {
      case RiskTableTabs.ASSET:
        return 'assetRiskCols';
      case RiskTableTabs.EXPOSURE:
        return 'exposureRiskCols';
      case RiskTableTabs.VAR:
        return 'varRiskCols';
      case RiskTableTabs.TRANSITIVE_STRESS:
        return 'transitiveStressRiskCols';
      case RiskTableTabs.HISTORICAL_STRESS:
        return 'historicalStressRiskCols';
      case RiskTableTabs.SENSITIVITY:
        return 'sensitivityRiskCols';
      case RiskTableTabs.LADDER:
        return 'ladderRiskCols';
      default:
        // Custom
        return 'customRiskCols';
    }
  });

  /**
   * Saves the new column selection and order
   */
  const onRiskSelectorModalConfirm = (selectedColumnNames: string[]) => {
    let path = activeAnalysisSubstep.value?.path as keyof PreferenceStorage;

    if (!path) return;
    if (
      paramPreferencesRiskTableMetricName.value === RiskTableTabs.ASSET ||
      paramPreferencesRiskTableMetricName.value === RiskTableTabs.CUSTOM
    ) {
      path = 'options';
    }

    set(preferences.value[path], storeValueToSave.value, selectedColumnNames);
  };

  return {
    allColumnNames,
    isCommodityDeltaGamma,
    isLadderTable,
    allowedSegmentLayers,
    paramPreferencesRiskTableMetricName,
    isIRLadderNormal,
    isTotalColumnInTable,
    basisPoint,
    assetColumnNames,
    exposureColumnNames,
    varColumnNames,
    transitiveStressColumnNames,
    historicalStressColumnNames,
    sensitivityColumns,
    defaultSensitivityColumns,
    ladderColumns,
    customColumnNames,
    allLadderColumns,
    riskMetricTabs,
    shouldShowColumnSelectorForRisk,
    onRiskSelectorModalConfirm,
    getTableComponentAndFields,
    newCommodityColumnNames,
  };
}
