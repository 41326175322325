import { computed, ref, shallowRef } from 'vue';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import {
  DiscoverChartTypes,
  DiscoveryPeriod,
  QuantSpacePeriod,
  SortedDiscoveryPeriods,
  VolatilityAdjustmentOptions,
} from '@/types/discover';
import { DataDiscoveryResponseDTO, PortfolioItemResponseDTO, StrategyItemResponseDTO } from '@/api-v2/web/discover';
import { UniverseVisualizationType } from '@/constants/UniverseVisualizationType';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { MstColorLogic } from '@/types/MstColorLogic';
import { useUniverseOptions } from '@/composables/useUniverseOptions';
import { METRICS_DATABASE_NAME } from '@/utils/metrics';

const { universe } = useUniverseOptions();

const graphTypeOptions: readonly DiscoverChartTypes[] = [
  DiscoverChartTypes.LINE,
  DiscoverChartTypes.MST,
  DiscoverChartTypes.SPIDER,
] as const;

const volatilityAdjustmentOptions: readonly VolatilityAdjustmentOptions[] = [
  VolatilityAdjustmentOptions.NONE,
  VolatilityAdjustmentOptions.VOLATILITY_PERCENT,
] as const;

const returnIntervals = [ReturnInterval.WEEKLY, ReturnInterval.MONTHLY] as const;

const periods = SortedDiscoveryPeriods;
const defaultPeriod = PeriodAbbrevEnum.ONE_Y as const;

const colorLogicOptions: readonly MstColorLogic[] = [
  MstColorLogic.ASSET_CLASS,
  MstColorLogic.FACTOR,
  MstColorLogic.PINNED,
  MstColorLogic.LABELS,
] as const;

const quantSpacePeriods = [PeriodAbbrevEnum.THREE_Y, PeriodAbbrevEnum.FIVE_Y] as const;

const shouldShowUniverse = ref(true);
const currentGraphType = ref(DiscoverChartTypes.LINE);
const currentReturnInterval = ref<ReturnInterval.WEEKLY | ReturnInterval.MONTHLY>(ReturnInterval.WEEKLY);
const currentPeriod = ref<DiscoveryPeriod>(PeriodAbbrevEnum.ONE_Y);
const currentColorLogic = ref(MstColorLogic.ASSET_CLASS);
const currentVolatilityAdjustment = ref(VolatilityAdjustmentOptions.NONE);

const universeVisualizationType = ref(UniverseVisualizationType.RISK_RETURN);

const riskReturnXAxisMetric = ref(METRICS_DATABASE_NAME.Volatility);
const riskReturnYAxisMetric = ref(METRICS_DATABASE_NAME.Return);

const quantSpacePeriod = ref<QuantSpacePeriod>(universe.value.period);
const quantSpaceUniverse = ref(universe.value.type);
const quantSpaceColor = ref(universe.value.color);

const volScalingEnabled = computed(
  () =>
    !shouldShowUniverse.value &&
    [DiscoverChartTypes.LINE, DiscoverChartTypes.SPIDER].includes(currentGraphType.value) &&
    currentVolatilityAdjustment.value === VolatilityAdjustmentOptions.VOLATILITY_PERCENT,
);

const allStrategies = shallowRef<readonly StrategyItemResponseDTO[]>([]);
const allPortfolios = shallowRef<readonly PortfolioItemResponseDTO[]>([]);

const allStrategiesByCode = shallowRef<{ [code: string]: StrategyItemResponseDTO }>({});
const allStrategiesById = shallowRef<{ [id: string]: StrategyItemResponseDTO }>({});
const allPortfoliosById = shallowRef<{ [portfolioId: string]: PortfolioItemResponseDTO }>({});
const allPortfoliosBySlug = shallowRef<{ [slug: string]: PortfolioItemResponseDTO }>({});

function SET_STRATEGY_UNIVERSE(universe: StrategyItemResponseDTO[]): void {
  const strategies = [...universe];

  for (const o of strategies) {
    Object.assign(o, { colorKey: o.id, rankKey: o.code });
  }
  allStrategies.value = Object.freeze(strategies);

  const strategiesByCode = strategies.map((o) => [o.code, o]);
  allStrategiesByCode.value = Object.freeze(Object.fromEntries(strategiesByCode));

  const strategiesById = strategies.map((o) => [o.id, o]);
  allStrategiesById.value = Object.freeze(Object.fromEntries(strategiesById));
}

function SET_PORTFOLIO_UNIVERSE(universe: PortfolioItemResponseDTO[]): void {
  const portfolios = [...universe];

  for (const o of portfolios) {
    Object.assign(o, { colorKey: o.portfolioId, rankKey: o.portfolioId });
  }
  allPortfolios.value = Object.freeze(portfolios);

  const portfoliosById = portfolios.map((o) => [o.portfolioId, o]);
  allPortfoliosById.value = Object.freeze(Object.fromEntries(portfoliosById));

  const portfoliosBySlug = portfolios.map((o) => [o.slug, o]);
  allPortfoliosBySlug.value = Object.freeze(Object.fromEntries(portfoliosBySlug));
}

function setUniverse(universe: DataDiscoveryResponseDTO) {
  SET_STRATEGY_UNIVERSE(universe.strategy ?? []);
  SET_PORTFOLIO_UNIVERSE(universe.portfolio ?? []);
}

export function useDiscoverStore() {
  return {
    /* Options and defaults */
    periods,
    quantSpacePeriods,
    defaultPeriod,
    returnIntervals,
    colorLogicOptions,
    graphTypeOptions,
    volatilityAdjustmentOptions,

    /* Current state */
    shouldShowUniverse,
    currentGraphType,
    currentReturnInterval,
    currentPeriod,
    currentColorLogic,
    currentVolatilityAdjustment,
    volScalingEnabled,
    universeVisualizationType,
    riskReturnXAxisMetric,
    riskReturnYAxisMetric,
    quantSpaceUniverse,
    quantSpaceColor,
    quantSpacePeriod,

    /* Data universe */
    allStrategies,
    allPortfolios,

    allStrategiesByCode,
    allStrategiesById,
    allPortfoliosById,
    allPortfoliosBySlug,

    /* Actions */
    setUniverse,
  };
}
