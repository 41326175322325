import { computed, ref, Ref } from 'vue';
import { PortfolioSidebarWidths } from '@/constants/SidebarWidths';
import { useRouteRef } from '@/composables/useRouter';
import { useActiveSubstep } from '@/composables/useAnalysisSteps';
import { getShouldShowInitialWeightsFn } from '@/utils/portfolioTree';
import usePortfolioTree, { getEquityBasketStatus } from './usePortfolioTree';
import { isEquityBasketPortfolioFn } from '@/utils/portfolioTree';

interface UseWidthsReturn {
  /**
   * Width (in px) for the sidebar in collapsed state
   */
  collapsedWidth: Ref<PortfolioSidebarWidths>;

  /**
   * Width (in px) for the sidebar in expanded state
   */
  expandedWidth: Ref<PortfolioSidebarWidths>;
}

// TODO WAA-10447: Refactor the useWidths to accept the portfolioTree as a parameter to use less globalized states
function useWidths(): UseWidthsReturn {
  const { masterPortfolioTree } = usePortfolioTree();

  const { isBasketPendingApproval, shouldShowBasketRebalancing } = getEquityBasketStatus(masterPortfolioTree);

  const shouldShowInitialWeights = getShouldShowInitialWeightsFn(masterPortfolioTree);

  const { isOnFrontierSubstep } = useActiveSubstep();

  const isEquityBasketPortfolio = isEquityBasketPortfolioFn(masterPortfolioTree);

  const collapsedWidth = computed((): PortfolioSidebarWidths => {
    if (isOnFrontierSubstep.value) return PortfolioSidebarWidths.COLLAPSED_WITH_TWO_COLS;
    return PortfolioSidebarWidths.COLLAPSED_WITH_ONE_COL;
  });

  const expandedWidth = computed((): PortfolioSidebarWidths => {
    let numCols = 2;

    if (shouldShowInitialWeights.value) {
      numCols++;
    }
    if (isOnFrontierSubstep.value) {
      numCols++;
    }

    if (isEquityBasketPortfolio.value) {
      if (shouldShowBasketRebalancing.value) return PortfolioSidebarWidths.EXPANDED_WITH_TEN_COLS;

      if (isBasketPendingApproval.value) return PortfolioSidebarWidths.EXPANDED_WITH_FIVE_COLS;

      return PortfolioSidebarWidths.EXPANDED_WITH_NINE_COLS;
    }

    if (numCols === 4) return PortfolioSidebarWidths.EXPANDED_WITH_FOUR_COLS;
    if (numCols === 3) return PortfolioSidebarWidths.EXPANDED_WITH_THREE_COLS;
    return PortfolioSidebarWidths.EXPANDED_WITH_TWO_COLS;
  });

  return {
    collapsedWidth,
    expandedWidth,
  };
}

export interface UseLeftSidebarReturns extends UseWidthsReturn {
  /**
   * Flag for indicating if the left sidebar is expanded.
   *
   * This ref is writable.
   */
  expanded: Ref<boolean>;

  /**
   * Flag for indicating if left sidebar should be shown base on current route.
   */
  shouldBeShown: Ref<boolean>;

  /**
   * Current width of the left sidebar should be at
   */
  width: Ref<PortfolioSidebarWidths>;
}

/**
 * Flag to expand the navigation panel.
 *
 * Do not move into useLeftSidebar() as base-chart still relies on direct access.
 *
 * Todo: Remove when we retire all amcharts v4 charts.
 */
export const isNavigationPanelExpanded = ref(false);

/**
 * Composable for getting the sidebar information related to widths and expand/collapse state.
 */
export function useLeftSidebar(): UseLeftSidebarReturns {
  const { collapsedWidth, expandedWidth } = useWidths();

  const expanded = isNavigationPanelExpanded;

  const route = useRouteRef();

  const shouldBeShown = computed(() => route.value.meta?.shouldNavigationPanelBeShown ?? false);

  const width = computed((): PortfolioSidebarWidths => {
    if (!shouldBeShown.value) {
      return PortfolioSidebarWidths.HIDDEN;
    }

    if (expanded.value) {
      return expandedWidth.value;
    }
    return collapsedWidth.value;
  });

  return {
    collapsedWidth,
    expandedWidth,
    shouldBeShown,
    expanded,
    width,
  };
}
