import request from '@/api-v2/request';
import { IConstituentRisk } from '@/types/IConstituentRisk';
import { IConstituentPositionDashboardQuery, IConstituentPositionTableQuery } from '@/types/IConstituentPosition';
import { IConstituentRiskOptions } from '@/types/analytics/ConstituentRisk';

/**
 * Fetches the constituent position tables
 */
export const getConstituentPositionTable = (query: IConstituentPositionTableQuery): Promise<IConstituentRisk> =>
  request({
    url: `/position-dashboard/dashboard/position-table`,
    method: 'post',
    data: query,
  }).then((o): IConstituentRisk => o.data);

/**
 * Fetches the constituent position dates for the given index unique identifier
 * @param indexUniqueIdentifier Given index unique identifier to find the dates for
 * @returns string[] of dates
 */
export const getConstituentPositionDates = (indexUniqueIdentifier: string): Promise<string[]> =>
  request({
    url: `/position-dashboard/${indexUniqueIdentifier}/dates`,
    method: 'get',
  }).then((o): string[] => o.data);

/**
 * Fetches the list of available fields for the position table and graphs
 * Currently no parameters passed as they are fixed fields
 * It is the same response as the Constituent Risk Options API
 */
export const getConstituentPositionOptions = (): Promise<IConstituentRiskOptions> =>
  request({
    url: `/position-dashboard/options`,
    method: 'get',
  }).then((o): IConstituentRiskOptions => o.data);

/**
 * Fetches the constituent position dashboard components for the given query
 * Currently only VarCharts component is supported
 * @param query Query to fetch the dashboard components
 */
export const getConstituentPositionDashboard = (query: IConstituentPositionDashboardQuery): Promise<IConstituentRisk> =>
  request({
    url: `/position-dashboard/dashboard`,
    method: 'post',
    data: query,
  }).then((o): IConstituentRisk => o.data);
