import { computed, ref, watch } from 'vue';
import { set } from 'vue-demi';
import usePortfolioTree, { getEquityBasketStatus } from './usePortfolioTree';
import {
  IPortfolioTreeStrategy,
  IPortfolioTreeSubportfolio,
  isPortfolioTreeStrategy,
  isPortfolioTreeSubportfolio,
} from '@/types/IPortfolioTree';
import { useToasts } from './useToasts';
import useTranslation from './useTranslation';
import { isEqual } from 'lodash';

const DEFAULT_BASKET_NOTIONAL = 1000000;

// TODO: WAA-9189 - Use usePortfolioTreeDraft instead of usePortfolioTree
export function useTriangulation(
  portfolioTreeItem: IPortfolioTreeStrategy | IPortfolioTreeSubportfolio | null = null,
  parentTree: IPortfolioTreeSubportfolio | null = null,
) {
  const { masterPortfolioTree } = usePortfolioTree();
  const { errorToast } = useToasts();
  const { translate } = useTranslation();

  const isMasterPortfolioTreeEmpty = computed(() => {
    if (!masterPortfolioTree.value) return true;
    return !masterPortfolioTree.value.portfolioTree.components.length;
  });

  const areAllUnitsLocked = computed(() => {
    if (!masterPortfolioTree.value) return false;
    if (!masterPortfolioTree.value.portfolioTree.isUnitLocked && isMasterPortfolioTreeEmpty.value) return false;
    return masterPortfolioTree.value.portfolioTree
      ? checkIfComponentUnitOrWeightIsLocked(masterPortfolioTree.value.portfolioTree, 'unit')
      : false;
  });

  const areAllWeightsLocked = computed(() => {
    if (!masterPortfolioTree.value) return false;
    if (!masterPortfolioTree.value.portfolioTree.isWeightingLocked && isMasterPortfolioTreeEmpty.value) return false;
    return masterPortfolioTree.value.portfolioTree
      ? checkIfComponentUnitOrWeightIsLocked(masterPortfolioTree.value.portfolioTree, 'weight')
      : false;
  });

  const checkIfComponentUnitOrWeightIsLocked = (
    component: IPortfolioTreeSubportfolio | IPortfolioTreeStrategy,
    propsToCheck: 'weight' | 'unit',
  ): boolean => {
    if (isPortfolioTreeStrategy(component)) {
      if (propsToCheck === 'weight' && component.isWeightingLocked === false) {
        return false;
      }
      if (propsToCheck === 'unit' && component.isUnitLocked === false) {
        return false;
      }
    }
    if (isPortfolioTreeSubportfolio(component)) {
      return component.components.every((subcomponent) =>
        checkIfComponentUnitOrWeightIsLocked(subcomponent, propsToCheck),
      );
    }

    return true;
  };

  const isPortfolioValueLocked = computed(() => {
    return masterPortfolioTree.value?.portfolioTree?.isPortfolioValueLocked ?? false;
  });

  const portfolioValue = computed(() => {
    return masterPortfolioTree.value?.portfolioTree.portfolioValue ?? DEFAULT_BASKET_NOTIONAL;
  });

  const portfolioCash = computed(() => {
    return masterPortfolioTree.value?.portfolioTree?.portfolioCash ?? 0;
  });

  const isMasterUnitLocked = computed(() => {
    return masterPortfolioTree.value?.portfolioTree?.isUnitLocked ?? false;
  });

  const isMasterWeightingLocked = computed(() => {
    return masterPortfolioTree.value?.portfolioTree?.isWeightingLocked ?? false;
  });

  /**
   * Check if all sub-components of master portfolio have their units locked
   * It differs from areAllUnitsLocked in a sense that areAllSubcomponentUnitsOfMasterLocked doesn't not check the root portfolio.
   * Its for handling the case in PortfolioWeightToolBar which when all sub-components' unit are locked, but the root portfolio's unit is not locked.
   * In that case, the watcher would update the root portfolio unit state to locked.
   * And this idea applies to areAllSubcomponentWeightingsOfMasterLocked and areAllWeightsLocked as well.
   */
  const areAllSubcomponentUnitsOfMasterLocked = computed(() => {
    if (!masterPortfolioTree.value) return false;
    if (!masterPortfolioTree.value.portfolioTree.isUnitLocked && isMasterPortfolioTreeEmpty.value) return false;
    return masterPortfolioTree.value.portfolioTree.components.every(
      (component: IPortfolioTreeStrategy | IPortfolioTreeSubportfolio) =>
        checkIfComponentUnitOrWeightIsLocked(component, 'unit'),
    );
  });

  const areAllSubcomponentWeightingsOfMasterLocked = computed(() => {
    if (!masterPortfolioTree.value) return false;
    if (!masterPortfolioTree.value.portfolioTree.isWeightingLocked && isMasterPortfolioTreeEmpty.value) return false;
    return masterPortfolioTree.value.portfolioTree.components.every(
      (component: IPortfolioTreeStrategy | IPortfolioTreeSubportfolio) =>
        checkIfComponentUnitOrWeightIsLocked(component, 'weight'),
    );
  });

  /**
   * Similar to areAllSubcomponentUnitsOfMasterLocked,
   * but it checks if all sub-components' of the portfolioTreeItem have their units locked.
   * And this idea applies to areAllSubcomponentWeightingsLocked as well.
   */
  const areAllSubcomponentUnitsLocked = computed(() => {
    if (!portfolioTreeItem || isPortfolioTreeStrategy(portfolioTreeItem)) return false;
    return portfolioTreeItem.components.every((component: IPortfolioTreeStrategy | IPortfolioTreeSubportfolio) =>
      checkIfComponentUnitOrWeightIsLocked(component, 'unit'),
    );
  });

  const areAllSubcomponentWeightingsLocked = computed(() => {
    if (!portfolioTreeItem || isPortfolioTreeStrategy(portfolioTreeItem)) return false;
    return portfolioTreeItem.components.every((component: IPortfolioTreeStrategy | IPortfolioTreeSubportfolio) =>
      checkIfComponentUnitOrWeightIsLocked(component, 'weight'),
    );
  });

  const isNotionalLockHovered = ref(false);

  /**
   * If lock has been changed during current hover session, then don't flip the result
   */
  const hasNotionalLockBeenChanged = ref(false);

  const doesNotionalLockAppearLocked = computed(() => {
    if (isNotionalLockHovered.value && !hasNotionalLockBeenChanged.value) return !isPortfolioValueLocked.value;
    return isPortfolioValueLocked.value;
  });

  const toggleNotionalLock = () => {
    if (!masterPortfolioTree.value) return;
    if (areAllUnitsLocked.value) {
      errorToast(
        translate({ path: 'TOASTS.NAVIGATION_PANEL.TRIANGULATION.LOCKING.CANNOT_LOCK_BOTH_NOTIONAL_AND_UNIT' }),
      );
      return;
    }
    set(masterPortfolioTree.value.portfolioTree, 'isPortfolioValueLocked', !isPortfolioValueLocked.value);
    hasNotionalLockBeenChanged.value = true;
  };

  const onLeaveNotionalLockHover = () => {
    isNotionalLockHovered.value = false;
    hasNotionalLockBeenChanged.value = false;
  };

  const isUnitLocked = ref(isMasterUnitLocked.value);
  const isWeightingLocked = ref(isMasterWeightingLocked.value);

  const isUnitLockHovered = ref(false);
  const isWeightingLockHovered = ref(false);

  const hasUnitLockBeenChanged = ref(false);
  const hasWeightingLockBeenChanged = ref(false);

  const doesUnitLockAppearLocked = computed(() => {
    if (isUnitLockHovered.value && !hasUnitLockBeenChanged.value) return !isUnitLocked.value;
    return isUnitLocked.value;
  });

  const doesWeightingLockAppearLocked = computed(() => {
    if (isWeightingLockHovered.value && !hasWeightingLockBeenChanged.value) return !isWeightingLocked.value;
    return isWeightingLocked.value;
  });

  const toggleUnitLock = () => {
    // If both weight and unit are locked, then unlock unit
    if (isUnitLocked.value && isWeightingLocked.value) {
      isUnitLocked.value = !isUnitLocked.value;
      hasUnitLockBeenChanged.value = true;
      return;
    }

    // If weighting or notional is locked, then we can't lock unit
    if (isWeightingLocked.value || isAnyWeightingLocked.value) {
      errorToast(translate({ path: 'TOASTS.NAVIGATION_PANEL.TRIANGULATION.LOCKING.CANNOT_LOCK_BOTH_WEIGHT_AND_UNIT' }));
      return;
    }
    if (isPortfolioValueLocked.value) {
      errorToast(
        translate({ path: 'TOASTS.NAVIGATION_PANEL.TRIANGULATION.LOCKING.CANNOT_LOCK_BOTH_NOTIONAL_AND_UNIT' }),
      );
      return;
    }

    isUnitLocked.value = !isUnitLocked.value;
    hasUnitLockBeenChanged.value = true;
  };

  const toggleWeightingLock = () => {
    // If both weight and unit are locked, then unlock unit
    if (isUnitLocked.value && isWeightingLocked.value) {
      isWeightingLocked.value = !isWeightingLocked.value;
      hasWeightingLockBeenChanged.value = true;
      return;
    }

    // If unit is locked, then we can't lock unit
    if (isUnitLocked.value || isAnyUnitLocked.value) {
      errorToast(translate({ path: 'TOASTS.NAVIGATION_PANEL.TRIANGULATION.LOCKING.CANNOT_LOCK_BOTH_WEIGHT_AND_UNIT' }));
      return;
    }
    isWeightingLocked.value = !isWeightingLocked.value;
    hasWeightingLockBeenChanged.value = true;
  };

  const onLeaveUnitLockHover = () => {
    isUnitLockHovered.value = false;
    hasUnitLockBeenChanged.value = false;
  };

  const onLeaveWeightingLockHover = () => {
    isWeightingLockHovered.value = false;
    hasWeightingLockBeenChanged.value = false;
  };

  const isItemUnitLocked = computed(() => {
    if (!portfolioTreeItem) return null;
    return (portfolioTreeItem.isUnitLocked || isMasterUnitLocked.value) ?? false;
  });

  const isItemWeightingLocked = computed(() => {
    if (!portfolioTreeItem) return null;
    return portfolioTreeItem.isWeightingLocked ?? false;
  });

  const isItemUnitLockHovered = ref(false);

  /**
   * If lock has been changed during current hover session, then don't flip the result
   */
  const hasItemUnitLockBeenChanged = ref(false);

  const doesItemUnitLockAppearLocked = computed(() => {
    if (isMasterUnitLocked.value) return true;
    if (isItemUnitLockHovered.value && !hasItemUnitLockBeenChanged.value) return !isItemUnitLocked.value;
    return isItemUnitLocked.value;
  });

  const shouldInheritUnitLock = computed(() => {
    return parentTree?.isUnitLocked ?? false;
  });

  const toggleItemUnitLock = () => {
    if (!portfolioTreeItem) return;
    if (shouldInheritUnitLock.value) return;
    // If both weight and unit are locked, then unlock unit
    if (isItemWeightingLocked.value && isItemUnitLocked.value) {
      set(portfolioTreeItem, 'isUnitLocked', !isItemUnitLocked.value);
      hasItemUnitLockBeenChanged.value = true;
    }
    if (isItemWeightingLocked.value) {
      errorToast(translate({ path: 'TOASTS.NAVIGATION_PANEL.TRIANGULATION.LOCKING.CANNOT_LOCK_BOTH_WEIGHT_AND_UNIT' }));
      return;
    }
    set(portfolioTreeItem, 'isUnitLocked', !isItemUnitLocked.value);
    hasItemUnitLockBeenChanged.value = true;
  };

  const onLeaveItemUnitLockHover = () => {
    isItemUnitLockHovered.value = false;
    hasItemUnitLockBeenChanged.value = false;
  };

  const isItemWeightingLockHovered = ref(false);

  /**
   * If lock has been changed during current hover session, then don't flip the result
   */
  const hasItemWeightingLockBeenChanged = ref(false);

  const doesItemWeightingLockAppearLocked = computed(() => {
    if (isMasterWeightingLocked.value) return true;
    if (isItemWeightingLockHovered.value && !hasItemWeightingLockBeenChanged.value) return !isItemWeightingLocked.value;
    return isItemWeightingLocked.value;
  });

  const shouldInheritWeightingLock = computed(() => {
    return parentTree?.isWeightingLocked ?? false;
  });

  const toggleItemWeightingLock = () => {
    if (!portfolioTreeItem) return;
    if (shouldInheritWeightingLock.value) return;
    // If both weight and unit are locked, then unlock weighting
    if (isItemUnitLocked.value && isItemWeightingLocked.value) {
      set(portfolioTreeItem, 'isWeightingLocked', !isItemWeightingLocked.value);
      hasItemWeightingLockBeenChanged.value = true;
    }
    if (isItemUnitLocked.value) {
      errorToast(translate({ path: 'TOASTS.NAVIGATION_PANEL.TRIANGULATION.LOCKING.CANNOT_LOCK_BOTH_WEIGHT_AND_UNIT' }));
      return;
    }
    set(portfolioTreeItem, 'isWeightingLocked', !isItemWeightingLocked.value);
    hasItemWeightingLockBeenChanged.value = true;
  };

  const onLeaveItemWeightingLock = () => {
    isItemWeightingLockHovered.value = false;
    hasItemWeightingLockBeenChanged.value = false;
  };

  const isEverythingUnlocked = computed(() => {
    return (
      !isItemWeightingLocked.value &&
      !isItemUnitLocked.value &&
      !areAllUnitsLocked.value &&
      !areAllWeightsLocked.value &&
      !isPortfolioValueLocked.value
    );
  });

  const isAnyUnitLocked = computed(() => {
    if (isMasterUnitLocked.value) return true;
    return masterPortfolioTree.value?.portfolioTree.components.some((component) => {
      return checkIfAnyUnitOrWeightingIsLocked(component, 'unit');
    });
  });

  const isAnyWeightingLocked = computed(() => {
    if (isMasterWeightingLocked.value) return true;
    return masterPortfolioTree.value?.portfolioTree.components.some((component) => {
      return checkIfAnyUnitOrWeightingIsLocked(component, 'weight');
    });
  });

  const checkIfAnyUnitOrWeightingIsLocked = (
    component: IPortfolioTreeSubportfolio | IPortfolioTreeStrategy,
    propsToCheck: 'unit' | 'weight',
  ): boolean => {
    if (isPortfolioTreeStrategy(component)) {
      if (propsToCheck === 'unit' && component.isUnitLocked === true) {
        return true;
      }
      if (propsToCheck === 'weight' && component.isWeightingLocked === true) {
        return true;
      }
    }
    if (isPortfolioTreeSubportfolio(component)) {
      return component.components.some((subcomponent) => checkIfAnyUnitOrWeightingIsLocked(subcomponent, propsToCheck));
    }
    return false;
  };

  const { isReadOnlyBasket } = getEquityBasketStatus(masterPortfolioTree);

  watch(
    () => masterPortfolioTree.value,
    (newVal, oldVal) => {
      if (newVal && newVal.portfolioTree && !isEqual(newVal, oldVal)) {
        // For read only basket, we locked the weighting
        if (isReadOnlyBasket.value && !newVal.portfolioTree.isWeightingLocked) {
          set(newVal.portfolioTree, 'isWeightingLocked', true);
          return;
        }

        // for normal basket, we lock the unit
        if (!newVal.portfolioTree.isUnitLocked) {
          set(newVal.portfolioTree, 'isUnitLocked', true);
        }
      }
    },
  );

  return {
    isMasterWeightingLocked,
    isMasterUnitLocked,
    areAllWeightsLocked,
    areAllUnitsLocked,
    isPortfolioValueLocked,
    portfolioValue,
    areAllSubcomponentUnitsOfMasterLocked,
    areAllSubcomponentWeightingsOfMasterLocked,
    isNotionalLockHovered,
    doesNotionalLockAppearLocked,
    toggleNotionalLock,
    onLeaveNotionalLockHover,
    isUnitLocked,
    isWeightingLocked,
    doesUnitLockAppearLocked,
    doesWeightingLockAppearLocked,
    toggleUnitLock,
    isUnitLockHovered,
    onLeaveUnitLockHover,
    toggleWeightingLock,
    isWeightingLockHovered,
    onLeaveWeightingLockHover,
    isItemUnitLocked,
    doesItemUnitLockAppearLocked,
    toggleItemUnitLock,
    isEverythingUnlocked,
    isItemUnitLockHovered,
    onLeaveItemUnitLockHover,
    isItemWeightingLocked,
    doesItemWeightingLockAppearLocked,
    onLeaveItemWeightingLock,
    toggleItemWeightingLock,
    isItemWeightingLockHovered,
    areAllSubcomponentUnitsLocked,
    areAllSubcomponentWeightingsLocked,
    DEFAULT_BASKET_NOTIONAL,
    portfolioCash,
    isAnyUnitLocked,
    isAnyWeightingLocked,
  };
}
