<!-- eslint-disable vue/no-lone-template -->
<template>
  <StandardModal
    id="confirmation-modal"
    v-model="shown"
    static
    centered
    no-close-on-backdrop
    footer-class="border-top-0"
    :dismissable="true"
    :on-cancel="() => $emit('cancel')"
  >
    <template>
      <h4 class="text-center mb-3">
        {{ promptTitle ?? translate({ path: 'SAVE_CHANGES_PROMPT.TITLE.UNSAVED_CHANGES' }) }}
      </h4>
      <span class="text-center font-weight-400 mb-0">
        {{ promptSubtitle ?? translate({ path: 'CONFIRMATION_DIALOGS.DELETE_CONFIRMATION' }) }}
      </span>
    </template>
    <template #footer="{ cancel }">
      <div class="d-flex justify-content-between">
        <b-button
          class="w-20 border"
          @click="cancel"
        >
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </b-button>
        <b-button
          variant="accent"
          class="w-20"
          @click="$emit('confirm')"
        >
          {{ confirmLabel ?? translate({ path: 'GLOBAL.CONFIRM' }) }}
        </b-button>
      </div>
    </template>
  </StandardModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import useTranslation from '@/composables/useTranslation';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'ConfirmationModal',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    promptTitle: {
      type: String,
      required: false,
    },
    promptSubtitle: {
      type: String,
      required: false,
    },
    confirmLabel: {
      type: String,
      required: false,
    },
    onCancel: {
      type: Function as PropType<() => Promise<void> | void>,
      required: false,
      default: () => null,
    },
  },
  emits: {
    confirm: () => true,
    cancel: () => true,
  },

  setup(props, { emit }) {
    const { translate } = useTranslation();

    const shown = useVModel(props, 'value', emit);

    return {
      translate,
      shown,
    };
  },
});
</script>
