import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { cloneDeep } from 'lodash';
import store from '@/store';
import { IPortfolioTree, ISetPortfolioTreeParams } from '@/types/IPortfolioTree';
import {
  setPortfolioTree,
  savePortfolioTree,
  getPortfolioTreeSnapshot,
  getPortfolioTreeBacktest,
} from '@/api-v2/web/portfolio-trees';
import axios from 'axios';
import { IPortfolioTreeSnapshotResponse } from '@/types/IPortfolioTreeSnapshot';
import { IPortfolioTreeBacktestResponse } from '@/types/IPortfolioTreeBacktest';
import { SaveChangesStore, SaveItemUniqueKeys } from './SaveChangesStore';
import AnalyticsStore from './AnalyticsStore';

export enum PortfolioLoadingFailReason {
  NotFound = 'NotFound',
  NetworkError = 'NetworkError',
  Other = 'Other',
}

@Module({ dynamic: true, store, name: 'Portfolio', namespaced: true })
class Portfolio extends VuexModule {
  public portfolioTreeUnderAnalysis: IPortfolioTree | null = null;
  public portfolioTreeLoadingFailed: PortfolioLoadingFailReason | null = null;

  public availableBacktests: IPortfolioTreeBacktestResponse[] | null = null;
  public availableSnapshots: IPortfolioTreeSnapshotResponse[] = [];

  public get hasActiveCodes(): boolean {
    if (!this.portfolioTreeUnderAnalysis) return false;
    if (this.portfolioTreeUnderAnalysis.portfolioTree.flattenedActiveCodes === undefined) return false;
    return this.portfolioTreeUnderAnalysis.portfolioTree.flattenedActiveCodes.length !== 0;
  }

  @Mutation
  private SET_PORTFOLIO_TREE_UNDER_ANALYSIS({ portfolioTree }: { portfolioTree: IPortfolioTree | null }): void {
    this.portfolioTreeUnderAnalysis = cloneDeep(portfolioTree);
  }

  /**
   * IMPORTANT: this must be set ONCE per page
   */
  @Action({ rawError: true })
  public async SetPortfolioTreeUnderAnalysis({
    portfolioTree,
    suppressErrors = false,
    setPeriod = true,
  }: {
    portfolioTree: IPortfolioTree | null;
    suppressErrors?: boolean;
    setPeriod?: boolean;
  }): Promise<void> {
    if (this.portfolioTreeLoadingFailed) this.SET_PORTFOLIO_TREE_LOADING_FAILED(null);

    // prevent item under analysis from being null
    if (portfolioTree !== null)
      await AnalyticsStore.SetItemUnderAnalysis({
        newVal: portfolioTree.portfolioTree,
        draft: portfolioTree,
        suppressErrors,
        setPeriod,
      });

    // but allow portfolio tree under analysis to be null (since item under analysis could be a strategy)
    this.SET_PORTFOLIO_TREE_UNDER_ANALYSIS({ portfolioTree });
  }

  @Mutation
  private SET_PORTFOLIO_TREE_LOADING_FAILED(failReason: PortfolioLoadingFailReason | null): void {
    this.portfolioTreeLoadingFailed = failReason;
  }
  @Action({ rawError: true })
  public async SetPortfolioTreeFailReason(failReason: PortfolioLoadingFailReason | null): Promise<void> {
    this.SET_PORTFOLIO_TREE_LOADING_FAILED(failReason);
  }

  @Action({ rawError: true })
  public async SetPortfolioTree({
    slug,
    payload,
  }: {
    slug: string;
    payload: ISetPortfolioTreeParams;
  }): Promise<IPortfolioTree | null> {
    const tree = await setPortfolioTree(slug, payload);
    this.SetPortfolioTreeUnderAnalysis({ portfolioTree: tree });
    SaveChangesStore.RemoveItemsToSave({ keys: SaveItemUniqueKeys.PORTFOLIO_TREE_DRAFT });
    return tree;
  }

  @Action({ rawError: true })
  public async SavePortfolioTree({ slug }: { slug: string }): Promise<IPortfolioTree> {
    const tree = await savePortfolioTree(slug);
    SaveChangesStore.RemoveItemsToSave({
      keys: [SaveItemUniqueKeys.PORTFOLIO_TREE_DRAFT, SaveItemUniqueKeys.PORTFOLIO_TREE],
    });

    return tree;
  }

  @Mutation
  private SET_PORTFOLIO_TREE_BACKTESTS({ backtests }: { backtests: IPortfolioTreeBacktestResponse[] | null }): void {
    this.availableBacktests = cloneDeep(backtests);
  }
  @Action({ rawError: true })
  public async GetPortfolioTreeBacktest(params: {
    slug: string;
  }): Promise<IPortfolioTreeBacktestResponse[] | undefined> {
    try {
      const backtests = await getPortfolioTreeBacktest(params);
      if (!axios.isAxiosError(backtests)) {
        this.SET_PORTFOLIO_TREE_BACKTESTS({ backtests });
      }
      return backtests;
    } catch {
      return [];
    }
  }

  @Mutation
  private SET_PORTFOLIO_TREE_SNAPSHOTS({ snapshots }: { snapshots: IPortfolioTreeSnapshotResponse[] }): void {
    this.availableSnapshots = cloneDeep(snapshots);
  }
  @Action({ rawError: true })
  public async GetPortfolioTreeSnapshot(params: {
    slug: string;
  }): Promise<IPortfolioTreeSnapshotResponse[] | undefined> {
    try {
      const snapshots = await getPortfolioTreeSnapshot(params);
      if (!axios.isAxiosError(snapshots)) {
        this.SET_PORTFOLIO_TREE_SNAPSHOTS({ snapshots: snapshots });
      }
      return snapshots;
    } catch {
      return [];
    }
  }
}

export const PortfolioModule = getModule(Portfolio);
