export enum METRICS {
  returnCum = 'Return',
  return = 'Return p.a.',
  volatility = 'Volatility',
  sharpe = 'Sharpe',
  infoRatio = 'Info. Ratio',
  skew = 'Skewness',
  kurtosis = 'Kurtosis',
  jarqueBeraPValue = 'Jarque-Bera',
  calmar = 'Calmar',
  omega = 'Omega',
  sortino = 'Sortino',
  var = 'VaR 95%',
  var99 = 'VaR 99%',
  mvar = 'ModVaR 95%',
  mvar99 = 'ModVaR 99%',
  cvar = 'CVaR 95%',
  cvar99 = 'CVaR 99%',
  avgDD = 'Avg DD',
  maxDD = 'Max DD',
  percentPosWeeks = '%Up Week',
  percentPosMonths = '%Up Month',
}

export enum METRICS_DATABASE_NAME {
  ReturnCumulative = 'returnCum',
  Return = 'return',
  Volatility = 'volatility',
  Sharpe = 'sharpe',
  InfoRatio = 'infoRatio',
  Skew = 'skew',
  Kurtosis = 'kurtosis',
  JarqueBera = 'jarqueBeraPValue',
  Calmar = 'calmar',
  Omega = 'omega',
  Sortino = 'sortino',
  Var = 'var',
  Var99 = 'var99',
  Mvar = 'mvar',
  Mvar99 = 'mvar99',
  Cvar = 'cvar',
  Cvar99 = 'cvar99',
  AvgDD = 'avgDD',
  MaxDD = 'maxDD',
  PercentPosWeeks = 'percentPosWeeks',
  PercentPosMonths = 'percentPosMonths',
  Autocorrelation = 'autocor',
}

export enum BLM_METRICS_DATABASE_NAME {
  Return = 'blmReturn',
  Volatility = 'blmVolatility',
  Sharpe = 'blmSharpe',
  InfoRatio = 'blmInfoRatio',
}

export enum BLM_METRICS_FULL_NAME {
  Return = `BLM Return p.a.`,
  Volatility = 'BLM Volatility',
  Sharpe = 'BLM Sharpe',
  InfoRatio = 'BLM Info. Ratio',
}

export enum METRICS_FULL_NAME {
  ReturnCumulative = 'Return',
  Return = 'Return p.a.',
  Volatility = 'Volatility',
  Sharpe = 'Sharpe',
  InfoRatio = 'Info. Ratio',
  Skew = 'Skewness',
  Kurtosis = 'Kurtosis',
  Calmar = 'Calmar',
  Omega = 'Omega',
  Sortino = 'Sortino',
  Var = 'VaR 95%',
  Mvar = 'ModVaR 95%',
  Cvar = 'CVaR 95%',
  Var99 = 'VaR 99%',
  Mvar99 = 'ModVaR 99%',
  Cvar99 = 'CVaR 99%',
  AvgDD = 'Avg DD',
  MaxDD = 'Max DD',
  PercentPosWeeks = '%Up Week',
  PercentPosMonths = '%Up Month',
  Autocorrelation = 'Autocorrelation',
  JarqueBera = 'Jarque-Bera',
}

export enum FACTSHEET_RISK_METRICS_DATABASE_NAME {
  WinRate = 'winRate',
  WinLossRatio = 'winLossRatio',
  UpHitRate = 'upHitRate',
  DownHitRate = 'downHitRate',
  UpsideCapture = 'upsideCapture',
  DownsideCapture = 'downsideCapture',
}

export enum MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME {
  MonthlyReturnPA = 'monthlyReturnPA',
  MonthlyVolatility = 'monthlyVolatility',
  MonthlySkew = 'monthlySkew',
  MonthlyKurtosis = 'monthlyKurtosis',
}

export enum FACTSHEET_RISK_METRICS_FULL_NAME {
  WinRate = 'Win Rate',
  WinLossRatio = 'Win/Loss Ratio',
  UpHitRate = 'Up Hit Rate',
  DownHitRate = 'Down Hit Rate',
  UpsideCapture = 'Upside Capture',
  DownsideCapture = 'Downside Capture',
}

export const MetricUnit = {
  returnCum: '%',
  return: '%',
  blmReturn: '%',
  volatility: '%',
  blmVolatility: '%',
  sharpe: '',
  blmSharpe: '',
  infoRatio: '',
  blmInfoRatio: '',
  skew: '',
  kurtosis: '',
  calmar: '',
  omega: '',
  sortino: '',
  var: '%',
  mvar: '%',
  cvar: '%',
  var99: '%',
  mvar99: '%',
  cvar99: '%',
  avgDD: '%',
  maxDD: '%',
  percentPosWeeks: '%',
  percentPosMonths: '%',
  autocor: '',
  jarqueBeraPValue: '%',
  winRate: '%',
  winLossRatio: '',
  upHitRate: '',
  downHitRate: '',
  upsideCapture: '',
  downsideCapture: '',
  monthlyReturnPA: '',
  monthlyVolatility: '',
  monthlySkew: '',
  monthlyKurtosis: '',
};

export const databaseTranslator: { [databaseName: string]: string } = {
  [METRICS_DATABASE_NAME.ReturnCumulative]: METRICS_FULL_NAME.ReturnCumulative,
  [METRICS_DATABASE_NAME.Return]: METRICS_FULL_NAME.Return,
  [METRICS_DATABASE_NAME.Volatility]: METRICS_FULL_NAME.Volatility,
  [METRICS_DATABASE_NAME.Sharpe]: METRICS_FULL_NAME.Sharpe,
  [METRICS_DATABASE_NAME.InfoRatio]: METRICS_FULL_NAME.InfoRatio,
  [METRICS_DATABASE_NAME.Skew]: METRICS_FULL_NAME.Skew,
  [METRICS_DATABASE_NAME.Kurtosis]: METRICS_FULL_NAME.Kurtosis,
  [METRICS_DATABASE_NAME.Calmar]: METRICS_FULL_NAME.Calmar,
  [METRICS_DATABASE_NAME.Omega]: METRICS_FULL_NAME.Omega,
  [METRICS_DATABASE_NAME.Sortino]: METRICS_FULL_NAME.Sortino,
  [METRICS_DATABASE_NAME.Var]: METRICS_FULL_NAME.Var,
  [METRICS_DATABASE_NAME.Mvar]: METRICS_FULL_NAME.Mvar,
  [METRICS_DATABASE_NAME.Cvar]: METRICS_FULL_NAME.Cvar,
  [METRICS_DATABASE_NAME.Var99]: METRICS_FULL_NAME.Var99,
  [METRICS_DATABASE_NAME.Mvar99]: METRICS_FULL_NAME.Mvar99,
  [METRICS_DATABASE_NAME.Cvar99]: METRICS_FULL_NAME.Cvar99,
  [METRICS_DATABASE_NAME.AvgDD]: METRICS_FULL_NAME.AvgDD,
  [METRICS_DATABASE_NAME.MaxDD]: METRICS_FULL_NAME.MaxDD,
  [METRICS_DATABASE_NAME.PercentPosMonths]: METRICS_FULL_NAME.PercentPosMonths,
  [METRICS_DATABASE_NAME.PercentPosWeeks]: METRICS_FULL_NAME.PercentPosWeeks,
  [METRICS_DATABASE_NAME.Autocorrelation]: METRICS_FULL_NAME.Autocorrelation,
  [METRICS_DATABASE_NAME.JarqueBera]: METRICS_FULL_NAME.JarqueBera,

  assetClass: 'Asset Class',
  code: 'Index Code',
  currency: 'Currency',
  factor: 'Factor',
  historyStartDate: 'History Start Date',
  provider: 'Index Provider',
  indexSource: 'Index Source',
  inceptionDate: 'Live Date',
  returnCategory: 'Net Return',
  region: 'Region',
  returnType: 'Return Type',
  style: 'Style',
  source: 'Source',
  volTarget: 'Vol Target',
  weighting: 'Weighting',
  type: 'Type',
  shortName: 'Name',

  // private fund fields
  isin: 'ISIN',
  globalCat: 'Global Category',
  benchmarks: 'Benchmark(s)',
  dividendFrequency: 'Dividend',
  providerCompany: 'Provider',
  ucits: 'UCITS',
  managerNames: 'Manager(s)',
  domicile: 'Domicile',
  performanceFee: 'Performance Fee',
  legalStructure: 'Legal Structure',
  managementFee: 'Management Fee',

  // stock fields
  ticker: 'Ticker',
  sedol: 'SEDOL',
  sector: 'Sector',
  esgRating: 'ESG Rating',

  // this is a fake field type, just used for display on front end
  privateFundType: 'Type',

  [BLM_METRICS_DATABASE_NAME.InfoRatio]: BLM_METRICS_FULL_NAME.InfoRatio,
  [BLM_METRICS_DATABASE_NAME.Return]: BLM_METRICS_FULL_NAME.Return,
  [BLM_METRICS_DATABASE_NAME.Volatility]: BLM_METRICS_FULL_NAME.Volatility,
  [BLM_METRICS_DATABASE_NAME.Sharpe]: BLM_METRICS_FULL_NAME.Sharpe,

  [FACTSHEET_RISK_METRICS_DATABASE_NAME.WinRate]: FACTSHEET_RISK_METRICS_FULL_NAME.WinRate,
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.WinLossRatio]: FACTSHEET_RISK_METRICS_FULL_NAME.WinLossRatio,
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.UpHitRate]: FACTSHEET_RISK_METRICS_FULL_NAME.UpHitRate,
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.DownHitRate]: FACTSHEET_RISK_METRICS_FULL_NAME.DownHitRate,
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.UpsideCapture]: FACTSHEET_RISK_METRICS_FULL_NAME.UpsideCapture,
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.DownsideCapture]: FACTSHEET_RISK_METRICS_FULL_NAME.DownsideCapture,
};

export type IMetrics = keyof typeof METRICS_DATABASE_NAME | keyof typeof FACTSHEET_RISK_METRICS_DATABASE_NAME;
