<template>
  <b-collapse
    id="navbar-toggle-collapse"
    is-nav
    :class="!isSmallScreen ? 'd-flex' : ''"
  >
    <b-navbar-nav :class="!isSmallScreen ? 'ml-auto align-items-center' : ''">
      <!-- for the nav items, we disable the active route because otherwise it may lead to some
            unexpected behavior for the user. For example, if the user is on Constituent Risk Analysis
            and clicks the Analytics item, she will be brought to the Portfolio Construction page,
            despite the Analytics item being highlighted as active -->

      <b-nav-item-dropdown
        v-if="isSmallScreen && shouldShowApplicationSidebar"
        :text="translate({ path: 'APPLICATION_NAVBAR.DATA' })"
        right
        offset="1"
        class="px-2"
        menu-class="bg-primary text-white shadow"
        active-class="bg-light text-primary"
      >
        <div
          v-for="navItem in sidebarNavItems"
          :key="navItem.route"
        >
          <b-dropdown-item
            v-if="AnalysisStepItems[navItem.name].shouldShowStep"
            :key="navItem.name"
            :disabled="AnalysisStepItems[navItem.name].disabled"
            :active="
              route.name === AnalysisStepItems[navItem.name].route ||
              route.name === AnalysisStepItems[navItem.name].secondaryRoute
            "
            @click.stop="() => AnalysisStepItems[navItem.name].onClick()"
          >
            {{ translate({ path: 'APPLICATION_NAVBAR', item: navItem.name }) }}
          </b-dropdown-item>
        </div>
      </b-nav-item-dropdown>

      <b-nav-item
        v-for="navItem in navItems"
        :key="`${navItem.to}`"
        v-b-popover.hover.bottom="
          navItem.disabled
            ? {
                html: true,
                variant: 'danger',
                content: popoverContent,
              }
            : undefined
        "
        :disabled="navItem.disabled"
        :to="
          navItem.name === NavItemNames.ANALYTICS
            ? analyticsRoute
            : {
                name: navItem.to,
              }
        "
        :exact="navItem.name === NavItemNames.DATA"
        :active="navItem.name === NavItemNames.ANALYTICS && isAnalyticsTabActive"
        active-class="bg-light text-primary rounded px-3"
        class="px-2 font-weight-400"
        @click.stop="navItem.name === NavItemNames.ANALYTICS && isAnalyticsTabActive ? () => null : undefined"
      >
        {{ navItem.name }}
      </b-nav-item>

      <!-- Commented out for now, as we don't have a working locale changer -->
      <!-- <LocaleChanger v-if="doesUserHavePermissionToLocaleChanger" /> -->

      <b-nav-item
        v-if="shouldShowKnowledgeBaseButton"
        class="px-1"
        @click.stop="toggleSidebar"
      >
        <icon
          size="xl"
          icon="circle-question"
          :class="sidebarShown ? 'text-mint' : 'text-white '"
        />
      </b-nav-item>

      <b-nav-item-dropdown
        data-testid="user-dropdown"
        right
        offset="1"
        toggle-class="d-flex align-items-center"
        menu-class="dropdown-menu-light shadow"
        no-caret
        class="d-none d-md-block px-1"
      >
        <template #button-content>
          <icon
            size="xl"
            icon="circle-user"
            :fas="isUserImpersonating"
            class="text-white"
          />&nbsp;
          <span class="mimic-bv-caret text-white mt-1" />
        </template>
        <b-dd-form v-if="shouldDisplayEmulateProductionButton">
          <b-form-checkbox
            v-model="shouldEmulateProduction"
            name="should-emulate-production-button"
            switch
            class="d-flex align-items-center"
          >
            Emulate Production
          </b-form-checkbox>
        </b-dd-form>
        <b-dd-form v-if="shouldShowHideBetaFeaturesButton">
          <b-form-checkbox
            v-model="shouldHideBetaFeatures"
            data-testid="should-hide-beta-features-checkbox"
            name="should-hide-beta-features-button"
            switch
            class="d-flex align-items-center"
          >
            Hide Beta Features
          </b-form-checkbox>
        </b-dd-form>
        <b-dropdown-group
          v-if="shouldShowSwitchTeamSection"
          header="Switch Team"
        >
          <b-dd-form>
            <b-form-select
              v-model="selectedSubTeamId"
              :options="subTeams"
              @change="impersonateTeam"
            >
              {{ subTeams }}
            </b-form-select>
          </b-dd-form>
        </b-dropdown-group>
        <b-dropdown-divider v-if="shouldShowSwitchTeamSection" />
        <template v-for="(options, groupName, idx) in menuOptions">
          <b-dropdown-group :key="`group-${groupName}`">
            <b-dropdown-item
              v-for="option of options"
              :key="option.text"
              :to="option.to || null"
              :href="option.href || null"
              @click.stop="option.onClick"
            >
              <b-form-checkbox
                v-if="option.isToggle"
                v-model="option.toggleValue"
                :name="`toggle-${option.text}-button`"
                switch
                class="d-flex align-items-center"
              >
                <icon
                  :icon="option.icon"
                  :class="option.class"
                  :fas="option.fas"
                  fixed-width
                />
                {{ option.text }}
              </b-form-checkbox>
              <span v-else>
                <icon
                  :icon="option.icon"
                  :class="option.class"
                  :fas="option.fas"
                  fixed-width
                />
                {{ option.text }}
              </span>
            </b-dropdown-item>
          </b-dropdown-group>
          <b-dropdown-divider
            v-if="idx < Object.values(menuOptions).length - 1"
            :key="`divider-${idx}`"
          />
        </template>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-collapse>
</template>

<script lang="ts">
import Swal from 'sweetalert2';
import { UserModule } from '@/store/barrel';
import { UserPermission } from '@/constants/UserPermission';
import { groupBy } from 'lodash';
import { SaveChangesStore } from '@/store/modules/SaveChangesStore';
import { useHasPermission } from '@/composables/usePermission';
import { NavItemNames } from '@/constants/NavbarConstants';
import { RouteName } from '@/constants/RouteName';
import useEnv from '@/composables/useEnv';
import useUser from '@/composables/useUser';
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter, useRouteRef } from '@/composables/useRouter';
import { RawLocation } from 'vue-router';
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import { notNull } from '@/utils/notnull';
import { useSubTeamList } from '@/composables/queries/useUserQueries';
import { useMobileResponsiveUtilities } from '@/composables/useMobileResponsiveUtilities';
import { CLUSTERING_ANALYSIS, FACTSHEET, FOLDER, PERFORMANCE_ATTRIBUTION } from '@/types/analytics/AnalysisStep';
import { useAllStrategiesByCode, useDataDiscovery } from '@/composables/queries/useDataDiscovery';
import { PORTFOLIO_CONSTRUCTION } from '@/types/analytics/PortfolioConstruction';
import { hasAnalyticsPermission, isAnalyticsRoute } from '@/composables/useRouteChecks';
import useTranslation from '@/composables/useTranslation';
import { useLastUsedAnalysisStep, useLastUsedPortfolioSlug } from '@/composables/useCachedAnalysisParameters';
import { useKnowledgeBase } from '@/composables/useKnowledgeBase';
import { KnowledgeBaseMode } from '@/types/KnowledgeBase';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useClipboard } from '@vueuse/core';
import { useIndexUniqueIdentifier, useStrategyIdentifier } from '@/composables/useCorrectIdentifier';
import { useAnalysisStepItems } from '@/composables/useAnalysisSteps';
import { getImpersonateToken } from '@/api-v2/admin/users';
import { useApplicationSidebar } from '@/composables/useApplicationSidebar';
import { ClientConstants } from '@/constants/ClientConstants';
import { usePerformanceContributionOptions } from '@/composables/usePerformanceContributionData';
import { PerformanceAttributionStatus } from '@/constants/PerformanceAttributionStatus';
import { AuthURL } from '@/api-v2/web/auth';
import { useDiscoverStore } from '@/composables/useDiscoverStore';

interface MenuItem {
  section: string;
  text: string;
  icon: string;
  fas?: boolean;
  class?: string;
  onClick: () => void;
  to?: RawLocation;
  href?: string;
  isToggle?: boolean;
  toggleValue?: boolean;
}

function useMenuOptions() {
  const {
    isFauxWhitelabel,
    isReadOnlyPlatform,
    userWantsToUseStrategyMask,
    enableTeamDefaultPeriodReturnIntervalSettings,
    shouldUseAdminBackDoor,
  } = useFeatureFlag();

  const { user } = useUser();
  const { translate } = useTranslation();

  const impersonating = useHasPermission(UserPermission.IMPERSONATE);
  const isAdmin = useHasPermission(UserPermission.ADMIN);
  const canGenerateBearerToken = useHasPermission(UserPermission.ADMIN_GENERATE_BEARER_TOKEN);
  const unblock = useHasPermission(UserPermission.UNBLOCK);
  const platform = useHasPermission(UserPermission.PLATFORM);

  const blocks = computed(() => {
    return {
      quitImpersonation: {
        section: translate({ path: 'APPLICATION_NAVBAR.ADMIN' }),
        text: translate({ path: 'APPLICATION_NAVBAR.BACK_TO_MY_ACCOUNT' }),
        icon: 'square-arrow-left',
        href: AuthURL.unimpersonate,
        onClick: () => {},
      },
      toggleReadOnly: {
        section: translate({ path: 'APPLICATION_NAVBAR.ADMIN' }),
        text: shouldUseAdminBackDoor.value
          ? translate({ path: 'APPLICATION_NAVBAR.ENABLE_READ_ONLY' })
          : translate({ path: 'APPLICATION_NAVBAR.DISABLE_READ_ONLY' }),
        icon: 'hands-bound',
        onClick: () => {
          shouldUseAdminBackDoor.value = !shouldUseAdminBackDoor.value;
        },
      },
      toggleStrategyMask: {
        section: translate({ path: 'APPLICATION_NAVBAR.ADMIN' }),
        text: translate({ path: 'APPLICATION_NAVBAR.TOGGLE_STRATEGY_MASK' }),
        icon: 'mask',
        isToggle: true,
        toggleValue: userWantsToUseStrategyMask.value,
        onClick: () => {
          userWantsToUseStrategyMask.value = !userWantsToUseStrategyMask.value;
        },
      },
      adminConsole: {
        section: translate({ path: 'APPLICATION_NAVBAR.ADMIN' }),
        text: translate({ path: 'APPLICATION_NAVBAR.ADMIN_CONSOLE' }),
        icon: 'user-secret',
        to: {
          name: RouteName.CONSOLE,
        },
        onClick: () => {},
      },
      bearerToken: {
        section: translate({ path: 'APPLICATION_NAVBAR.ADMIN' }),
        text: 'Generate Bearer Token',
        icon: 'key',
        onClick: async () => {
          if (user.value?.id == null) {
            return;
          }

          const { copy } = useClipboard();
          const response = await getImpersonateToken(user.value.id.toString());
          await Swal.fire({
            title: 'Bearer Token',
            text: 'This bearer token is valid for 1 year and associated with your account. DO NOT GIVE THIS TO ANYONE ELSE.',
            confirmButtonText: 'Copy to Clipboard',
            preConfirm: () => copy(response.accessToken),
          });
        },
      },
      articles: {
        section: translate({ path: 'APPLICATION_NAVBAR.ADMIN' }),
        text: translate({ path: 'APPLICATION_NAVBAR.ARTICLES_PAGE' }),
        icon: 'newspaper',
        href: '/articles',
        onClick: () => {},
      },
      toggleApplyUserDefaultPeriod: {
        section: translate({ path: 'APPLICATION_NAVBAR.ADMIN' }),
        text: translate({ path: 'APPLICATION_NAVBAR.TOGGLE_APPLY_USER_DEFAULTS' }),
        icon: 'calendar-clock',
        isToggle: true,
        toggleValue: enableTeamDefaultPeriodReturnIntervalSettings.value,
        onClick: () => {
          enableTeamDefaultPeriodReturnIntervalSettings.value = !enableTeamDefaultPeriodReturnIntervalSettings.value;
        },
      },
      mail: {
        section: translate({ path: 'APPLICATION_NAVBAR.HELP' }),
        text: translate({ path: 'APPLICATION_NAVBAR.EMAIL_US' }),
        icon: 'paper-plane',
        href: `mailto:${import.meta.env.VITE_INFO_EMAIL}`,
        onClick: () => {},
      },
      userSettings: {
        section: translate({ path: 'APPLICATION_NAVBAR.SETTINGS' }),
        text: translate({ path: 'APPLICATION_NAVBAR.SETTINGS' }),
        icon: 'gear',
        fas: true,
        to: {
          name: RouteName.USER_SETTING,
        },
        onClick: () => {},
      },
      logout: {
        section: '',
        text: translate({ path: 'APPLICATION_NAVBAR.LOGOUT' }),
        icon: 'sign-out',
        href: '',
        to: {
          name: RouteName.LOGOUT,
        },
        onClick: () => {},
      },
    } satisfies Record<string, MenuItem>;
  });

  const defaultMenu = computed((): Array<MenuItem | null> => {
    const b = blocks.value;

    if (import.meta.env.VITE_CLIENT !== 'Premialab') {
      return [
        impersonating.value ? b.quitImpersonation : null,
        isAdmin.value && !impersonating.value ? b.adminConsole : null,
        unblock.value && platform.value ? b.userSettings : null,
        b.logout,
      ];
    }

    return [
      impersonating.value ? b.quitImpersonation : null,
      impersonating.value && (isReadOnlyPlatform.value || shouldUseAdminBackDoor.value) ? b.toggleReadOnly : null,
      isAdmin.value && !impersonating.value ? b.adminConsole : null,
      isAdmin.value && !impersonating.value && canGenerateBearerToken.value ? b.bearerToken : null,
      isAdmin.value && !impersonating.value ? b.articles : null,
      isAdmin.value ? b.toggleStrategyMask : null,
      isAdmin.value ? b.toggleApplyUserDefaultPeriod : null,
      !isFauxWhitelabel.value ? b.mail : null,
      unblock.value && platform.value && !isReadOnlyPlatform.value ? b.userSettings : null,
      b.logout,
    ];
  });

  const menuOptions = computed(() => {
    return groupBy(defaultMenu.value.filter(notNull), (item) => item.section);
  });

  return menuOptions;
}

interface SubTeamOption {
  label: string;
  options: { text: string; value: string }[];
}

function useSubTeams() {
  const { user } = useUser();
  const query = useSubTeamList();

  const subTeams = computed((): SubTeamOption[] => {
    if (user.value == null) {
      return [];
    }

    const subTeams: SubTeamOption[] = [];

    if (user.value.team != null) {
      subTeams.push({
        label: 'Team',
        options: [
          {
            text: user.value.team.name,
            value: user.value.team.id.toString(),
          },
        ],
      });
    }

    const data = query.data.value ?? [];

    if (data.length > 0) {
      subTeams.push({
        label: 'Subteams',
        options: data.map((subTeam) => {
          return {
            text: subTeam.name,
            value: subTeam.id.toString(),
          };
        }),
      });
    }

    return subTeams;
  });

  const selectedSubTeamId = ref<string | null>(null);
  watch(user, () => {
    selectedSubTeamId.value = user.value?.teamId.toString() ?? null;
  });

  const currentUserTeamId = computed(() => {
    if (user.value == null) {
      return null;
    }

    return user.value?.teamId.toString();
  });

  const impersonateTeam = async () => {
    if (!selectedSubTeamId.value) {
      return;
    }

    if (currentUserTeamId.value && currentUserTeamId.value === selectedSubTeamId.value) {
      // Quit impersonation
      window.location.href = AuthURL.teamImpersonate.switchBack;
      return;
    }

    window.location.href = AuthURL.teamImpersonate.impersonate(selectedSubTeamId.value);
  };

  const shouldShowSwitchTeamSection = computed(() => {
    return (query.data.value?.length ?? 0) > 0;
  });

  return {
    subTeams,
    selectedSubTeamId,
    impersonateTeam,
    shouldShowSwitchTeamSection,
  };
}

export type AnalysisStepNavItem = {
  shouldShowStep: boolean;
  disabled: boolean;
  onClick: () => void;
  route?: string | null;
  secondaryRoute?: string;
};

export default defineComponent({
  name: 'ApplicationNavbar',
  setup() {
    const { shouldEmulateProduction, isActuallyProduction } = useEnv();
    const { user, isUserAdmin, isUserImpersonating } = useUser();
    const { eventBus } = useGlobalEventBus();

    /**
     * UNIVERSE INSTANTIATION SINGLETON
     *
     * This logic is not truly used in this page, but the index universe is used extensively throughout the application
     * Here we instantiate it once and when it changes, we update the Discover Store.
     *
     * This is the way we must move forward to comply with tanstack-query's requirements to v5 because the
     * useQuery's `onSuccess` callback is being deprecated.
     */
    const { setUniverse } = useDiscoverStore();
    const universe = useDataDiscovery();
    watch(universe.data, (newVal) => {
      // Use structuredClone to force the object to not be reactive
      if (newVal) setUniverse(structuredClone(newVal));
    });

    const route = useRouteRef();

    const isDropzoneAccessible = useHasPermission(UserPermission.DROP_ZONE);
    const isMorningstarAccessible = useHasPermission(UserPermission.MORNING_STAR);
    const isProxyTableAccessible = isUserAdmin;

    const indexUniqueIdentifier = useIndexUniqueIdentifier();

    const allStrategiesByCode = useAllStrategiesByCode();

    const router = useRouter();

    const strategyIdentifier = useStrategyIdentifier();

    const { translate } = useTranslation();

    // TODO : Add all the application sidebar components with permissions over here
    // Right now only adding Factsheet and Performance Attribution

    // TODO : Add Factor Decomposition and Constituent Risk

    const hasPcaPermission = useHasPermission(UserPermission.PCA);

    const { availableAnalysisStepItems } = useAnalysisStepItems(route);

    const performanceContributionOptions = usePerformanceContributionOptions(strategyIdentifier);

    const isPerformanceContributionValidated = computed(
      () =>
        performanceContributionOptions.data.value?.parameters.bank?.status === PerformanceAttributionStatus.VALIDATED ||
        performanceContributionOptions.data.value?.parameters.ipv?.status === PerformanceAttributionStatus.VALIDATED,
    );

    const AnalysisStepItems = computed(() => {
      const retval: Record<string, AnalysisStepNavItem> = {
        [FOLDER.name]: {
          shouldShowStep: availableAnalysisStepItems.value.includes(FOLDER),
          disabled: false,
          onClick: () => eventBus.emit('show-analysis-tool-modal', route.value.name),
          route: null,
        },
        [FACTSHEET.name]: {
          shouldShowStep: availableAnalysisStepItems.value.includes(FACTSHEET),
          disabled: false,
          onClick: () => {
            const isStrategyFactsheet = allStrategiesByCode.data.value?.get(indexUniqueIdentifier.value) !== undefined;
            router.push({
              name: isStrategyFactsheet ? RouteName.STRATEGY_FACTSHEET : RouteName.PORTFOLIO_FACTSHEET,
              params: { indexUniqueIdentifier: indexUniqueIdentifier.value },
            });
          },
          route: RouteName.STRATEGY_FACTSHEET,
          secondaryRoute: RouteName.PORTFOLIO_FACTSHEET,
        },
        [PERFORMANCE_ATTRIBUTION.name]: {
          shouldShowStep: availableAnalysisStepItems.value.includes(PERFORMANCE_ATTRIBUTION),
          onClick: () => {
            router.push({
              name: RouteName.PERFORMANCE_ATTRIBUTION,
              params: { indexUniqueIdentifier: indexUniqueIdentifier.value },
            });
          },
          disabled: !isPerformanceContributionValidated.value,
          route: RouteName.PERFORMANCE_ATTRIBUTION,
        },
        [PORTFOLIO_CONSTRUCTION.name]: {
          shouldShowStep: availableAnalysisStepItems.value.includes(PORTFOLIO_CONSTRUCTION),
          onClick: () => {
            router.push({
              name: RouteName.PORTFOLIO_CONSTRUCTION,
              params: {
                indexUniqueIdentifier: indexUniqueIdentifier.value,
              },
            });
          },
          route: RouteName.PORTFOLIO_CONSTRUCTION,
          disabled: false,
        },
        [CLUSTERING_ANALYSIS.name]: {
          shouldShowStep: availableAnalysisStepItems.value.includes(CLUSTERING_ANALYSIS),
          onClick: () => {
            router.push({
              name: RouteName.CLUSTERING_ANALYSIS,
              params: {
                indexUniqueIdentifier: indexUniqueIdentifier.value,
              },
            });
          },
          route: RouteName.CLUSTERING_ANALYSIS,
          disabled: !hasPcaPermission.value,
        },
      };
      return retval;
    });

    // TODO : Add Factor Decomposition and Constituent Risk

    const sidebarNavItems = computed(() =>
      [FOLDER, PORTFOLIO_CONSTRUCTION, FACTSHEET, PERFORMANCE_ATTRIBUTION, CLUSTERING_ANALYSIS].filter(
        (x) => AnalysisStepItems.value[x.name].shouldShowStep,
      ),
    );

    const { shouldShowApplicationSidebar } = useApplicationSidebar();

    const shouldDisableMyLab = computed(() => {
      // Assume the user has access when the user not yet loaded to avoid the My Lab button flashing upon login
      if (!user.value) {
        return false;
      }
      return !isDropzoneAccessible.value && !isMorningstarAccessible.value && !isProxyTableAccessible.value;
    });

    const hasUnblockPermission = useHasPermission(UserPermission.UNBLOCK);
    const hasPlatformPermission = useHasPermission(UserPermission.PLATFORM);
    const hasApiPermission = useHasPermission(UserPermission.API);
    const hasAnyAnalyticsPermission = computed(
      () =>
        UserModule.user !== null &&
        hasAnalyticsPermission({ user: UserModule.user, currentRoute: route.value }) !== undefined,
    );

    const {
      fauxWhitelabelConfig,
      isFauxWhitelabel,
      canSeeInsights,
      canSeeMyLab,
      shouldDisplayEmulateProductionButton,
      shouldShowHideBetaFeaturesButton,
      shouldHideBetaFeatures,
    } = useFeatureFlag();

    /**
     * It's important to put the PARENT route for each of these tabs,
     * otherwise the router will not properly detect that the given tab
     * is active
     *
     * The disabled property will automatically generate a popover to upsell the feature.
     * This is not properly done dynamically - if the disabled property is true at any time
     * during loading, the popover will show and continue to show, even if the disabled property
     * becomes false. In order to prevent a false result, we don't show any of the tabs while
     * the user is null.
     */
    const defaultTabs = computed(() => {
      const base = hasUnblockPermission.value && hasPlatformPermission.value;

      if (!UserModule.user) return [];

      const hasUniqueHomeRoute = isFauxWhitelabel.value && fauxWhitelabelConfig.value?.homePageRoute;

      return [
        {
          name: NavItemNames.DATA,
          to: RouteName.DATA,
          shown: base && !hasUniqueHomeRoute,
          disabled: false,
        },
        {
          name: NavItemNames.ANALYTICS,
          to: RouteName.ANALYTICS,
          shown: base,
          disabled: !hasAnyAnalyticsPermission.value,
        },
        {
          name: NavItemNames.MY_LAB,
          to: RouteName.MY_LAB,
          shown: base && canSeeMyLab.value,
          disabled: shouldDisableMyLab.value,
        },
        {
          name: NavItemNames.INSIGHTS,
          to: RouteName.INSIGHTS,
          shown: base && canSeeInsights.value,
          disabled: false,
        },
        {
          name: NavItemNames.RESOURCES,
          to: RouteName.RESOURCES,
          shown: hasApiPermission.value || hasUnblockPermission.value,
          disabled: false,
        },
      ];
    });

    const popoverContent = computed(() => {
      return `Please contact<br><a href="mailto:${import.meta.env.VITE_CLIENT_SERVICES_EMAIL}">${
        import.meta.env.VITE_CLIENT_SERVICES_EMAIL
      }</a><br>to access to this feature.`;
    });

    const navItems = computed(() => {
      const items = defaultTabs.value.filter((o) => !user.value || o.shown);
      if (isSmallScreen.value && shouldShowApplicationSidebar.value) return items.filter((o) => o.name !== 'Data');
      return items;
    });

    const hasUnsavedChanges = computed(() => SaveChangesStore.hasChanges);

    // Commented out for now, as we don't have a working locale changer
    // const isAdmin = useHasPermission(UserPermission.ADMIN);

    // const doesUserHavePermissionToLocaleChanger = computed(() => {
    //   if (import.meta.env.VITE_CLIENT === 'Barclays') {
    //     return true;
    //   }

    //   return (
    //     isAdmin.value &&
    //     (route.value.name === RouteName.PORTFOLIO_FACTSHEET ||
    //       route.value.name === RouteName.STRATEGY_FACTSHEET ||
    //       route.value.name === RouteName.DATA ||
    //       route.value.name === RouteName.PERFORMANCE_ATTRIBUTION ||
    //       route.value.name === RouteName.ARTICLES ||
    //       route.value.name === RouteName.ARTICLE_FEED)
    //   );
    // });

    const { subTeams, selectedSubTeamId, impersonateTeam, shouldShowSwitchTeamSection } = useSubTeams();

    const menuOptions = useMenuOptions();

    const { isSmallScreen } = useMobileResponsiveUtilities();

    const { mode, sidebarShown, toggleSidebar } = useKnowledgeBase();

    /**
     * Knowledge Base button should be hidden if the user is on the knowledge base page
     */
    const shouldShowKnowledgeBaseButton = computed(() => mode.value !== KnowledgeBaseMode.PAGE);

    const isAnalyticsTabActive = computed(() => {
      return !!route.value.name && isAnalyticsRoute(route.value.name);
    });

    const { cachedAnalysisStep } = useLastUsedAnalysisStep();

    /**
     * If the user is on an analytics step, this will just return the current route
     *
     * Return the first analytics route that the user has permission to
     * This cannot be handled in the AnalyticsRouter because the redirect, which was previously used,
     * has no visibility into the current user.
     */
    const analyticsRoute = computed(() => {
      if (!UserModule.user) {
        return {
          name: RouteName.NOT_FOUND,
        };
      }

      const { portfolio: lastSlugUsed } = useLastUsedPortfolioSlug();
      const routeWPermission = hasAnalyticsPermission({
        user: UserModule.user,
        testRoute: route.value.name && isAnalyticsRoute(route.value.name) ? route.value.name : undefined,
        currentRoute: route.value,
      });

      const { defaultSlug = '', defaultRiskSlug = '' } = UserModule.user ?? {};

      if (routeWPermission === route.value.name) {
        // This condition just means that the user has permission to the current route.
        // We return the current route in order to make the nav item show as 'active' properly
        // and conditionally add a click handler to the nav item so that when the user clicks on the
        // button it does nothing. This is necessary because the active prop is not working properly
        // on the nav item.

        // if the user is not on an analytics step, then this button will be a link to either the
        // last *used* analysis step that the user has permission to, or if that does not exist, then
        // the first analytics route that they have permission to
        return {
          ...route.value,
          params: {
            indexUniqueIdentifier:
              lastSlugUsed.value || (route.value.name === RouteName.CONSTITUENT_RISK ? defaultRiskSlug : defaultSlug),
          },
        };
      }

      if (routeWPermission) {
        return {
          name:
            cachedAnalysisStep.value &&
            hasAnalyticsPermission({
              user: UserModule.user,
              testRoute: cachedAnalysisStep.value,
              currentRoute: route.value,
            }) === cachedAnalysisStep.value
              ? cachedAnalysisStep.value
              : routeWPermission,
          params: {
            indexUniqueIdentifier:
              lastSlugUsed.value || (routeWPermission === RouteName.CONSTITUENT_RISK ? defaultRiskSlug : defaultSlug),
          },
        };
      }

      return {
        name: RouteName.NOT_FOUND,
      };
    });

    return {
      navItems,
      shouldShowSwitchTeamSection,
      impersonateTeam,
      menuOptions,
      isUserAdmin,
      // doesUserHavePermissionToLocaleChanger,
      hasUnsavedChanges,
      isActuallyProduction,
      shouldEmulateProduction,
      popoverContent,
      selectedSubTeamId,
      subTeams,
      isSmallScreen,
      shouldShowApplicationSidebar,
      sidebarNavItems,
      AnalysisStepItems,
      route,
      RouteName,
      translate,
      NavItemNames,
      analyticsRoute,
      isAnalyticsTabActive,
      isUserImpersonating,
      shouldShowKnowledgeBaseButton,
      sidebarShown,
      toggleSidebar,
      ClientConstants,
      shouldDisplayEmulateProductionButton,
      shouldShowHideBetaFeaturesButton,
      shouldHideBetaFeatures,
    };
  },
});
</script>
